import '../../styles/Form.scss';
import './component.style.scss';
import Input from '../Input/Input';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useStore } from '../../store';
import RemoveButton from '../RemoveButton/RemoveButton';
import ProfessionsDataGrid from '../DataGrid/DataGrid';
import { Event } from '../../ts/types';
import mainService from '../../api/MainService';
import { businessDetailsFormText } from '../../db/formHebrewText';
import { setFormDataInLocalStorage } from '../../utils/localStorage';

export default function ProfessionsForm() {
	const {
		isFreeText,
		filterProfessionsByGroupId,
		getAllProfessions,
		updateFreeTextState,
		getProfessionsByQuery,
		link,
		handleQuery,
		queryValue,
		professionsByQuery,
		groups,
		getGroups,
		professions,
		createLink,
		currentGroup,
		chosenProfessions,
		updateCurrentGroupId,
		addToChosenProfessions,
		updateCurrentGroupText,
		getProfessionsByGroupId,
		removeFromChosenProfessions,
		defineSurfaceInputToDisplayOnStep5,
		checkIdMaslulim_34,
		checkPrivilegeByProfession,
	} = useStore().chosenProfessionsContext;
	const { setProfessionPhraseFormValues, professionPhraseFormValues, removePhrasesValues } = useStore().professionPhraseFormContainerContext;
	const { checkFormState } = useStore().activeStepContext;
	const { setGlobalRequiredFiles, globalRequiredFiles } = useStore().requiredFilesFormContainerContext;
	const { updateFormsContainer, updateFormsValidation } = useStore().formsContainerContext;
	const handleChange = (e: Event) => {
		updateCurrentGroupText(e);
	};
	const sortedProfessions = professions.sort((a: { profession: string }, b: { profession: string }) => {
		const aParts = a.profession.split('.');
		const bParts = b.profession.split('.');
		const aNumeric = parseFloat(aParts[0] + (aParts[1] || ''));
		const bNumeric = parseFloat(bParts[0] + (bParts[1] || ''));
		if (aNumeric < bNumeric) return -1;
		if (aNumeric > bNumeric) return 1;
		if (aParts[2] < bParts[2]) return -1;
		if (aParts[2] > bParts[2]) return 1;
		return 0;
	});

	useEffect(() => {
		getProfessionsByQuery(queryValue);
		filterProfessionsByGroupId();
		const fetchCheckListData = async () => {
			const files: { descrip: string; id: number; gov_api_code: number; idprofession: any; isRequired: boolean }[] = [];
			const uniqueItemsSet = new Set();
			const texts: string[] = [];
			for (const profession of chosenProfessions) {
				const data = await mainService.getCheckListIdsByIdProfession(profession.id);
				data.forEach((item: { gov_api_code: number; descrip: string; id: number; must_flag: number }) => {
					const uniqueKey = `${item.gov_api_code}_${item.id}`;
					if (!uniqueItemsSet.has(uniqueKey)) {
						uniqueItemsSet.add(uniqueKey);
						const isRequired = !!item.must_flag;
						texts.push(item.descrip.trim());
						files.push({
							descrip: item.descrip.trim(),
							id: item.id,
							gov_api_code: item.gov_api_code,
							idprofession: profession.id,
							isRequired,
						});
					}
				});
			}
			setGlobalRequiredFiles(files);
		};

		if (chosenProfessions?.length) fetchCheckListData();
		else setGlobalRequiredFiles([]);
	}, [chosenProfessions, queryValue]);

	useEffect(() => {
		if (currentGroup.text.length > 0) getProfessionsByGroupId(currentGroup.id);
	}, [currentGroup]);

	useEffect(() => {
		getGroups();
		getAllProfessions();
	}, []);

	useEffect(() => {
		const { cabahut_ptor, cabahut_maslul } = checkPrivilegeByProfession();
		checkFormState(chosenProfessions?.length > 0, 3);
		updateFormsContainer('chosenProfessions', chosenProfessions);
		updateFormsValidation('chosenProfessions', chosenProfessions?.length > 0);
		setProfessionPhraseFormValues((prev: any) => ({
			...prev,
			cabahut_ptor,
			cabahut_maslul,
		}));
		console.log(professionPhraseFormValues);

		checkPrivilegeByProfession();
		defineSurfaceInputToDisplayOnStep5();
		console.log(defineSurfaceInputToDisplayOnStep5());

		checkIdMaslulim_34();
	}, [chosenProfessions]);

	const handleCheck = (e: any, item: any) => {
		if (e.target.checked) {
			addToChosenProfessions(item);
			item.isChecked = true;
		} else {
			removeFromChosenProfessions(item);
			item.isChecked = false;
		}
	};

	const removeProfession = (item: any) => {
		removeFromChosenProfessions(item);
		removePhrasesValues(item);
	};

	useEffect(() => {
		setFormDataInLocalStorage(chosenProfessions, 'chosenProfessions');
	}, [chosenProfessions]);

	return (
		<div className="form form_professions">
			<div className="form-top-wrapper flex column" style={{ gap: 15 }}>
				<div className="flex column">
					<small className="required">*פריטים המסומנים בשורה אפורה לא ניתנים לבקשה באופן מקוון, אנא פנה לרשות למידע נוסף.</small>
					<small className="required" style={{ paddingTop: 10 }}>
						*שים לב - קביעת הפריטים אינה סופית וניתנת לשינוי בהתאם להחלטת הרשות.
					</small>
					<small className="required" style={{ paddingTop: 10 }}>
						*יש לבחור יותר מפריט אחד במידת הצורך.
					</small>
				</div>
				<h1 className="form__title">פריטי עיסוק</h1>
				<div className="form__top_third-step">
					<div className="form__top-search flex center">
						<button
							aria-label={isFreeText ? 'בחירה מרשימת קבוצות עיסוק' : 'ביצוע חיפוש לפי טקסט חופשי - בעת הזנת המידע בשדה, יבוצע חיפוש לפי טקסט חופשי'}
							type="button"
							className="form__top-modifier-button"
							onClick={updateFreeTextState}
						>
							{isFreeText ? 'בחירה מרשימת קבוצות עיסוק' : 'ביצוע חיפוש לפי טקסט חופשי'}
						</button>
						{isFreeText ? (
							<Input
								placeholder={'מחפש לפי טקסט חופשי : למשל מספרה או 1.4ב'}
								onChange={handleQuery}
								legend={''}
								name={''}
								value={queryValue}
								isRequired={false}
								type={''}
								errorMessage={''}
								min={0}
								max={25}
								pattern={''}
								ariaLabel={'בחירת קבוצת עיסוק רלוונטית'}
							/>
						) : (
							<>
								<InputLabel id="groupSelect" aria-label="בחר קבוצת עיסוק מהרשימה"></InputLabel>
								<Select
									labelId="groupSelect"
									aria-required="true"
									style={{ width: '100%' }}
									value={currentGroup.text}
									sx={{ height: 45, margin: '0px !important' }}
									onChange={(e: any) => handleChange(e)}
								>
									{groups.map((item: any) => (
										<MenuItem aria-label={item.descrip} value={item.descrip} key={item.id} dir="rtl" onClick={() => updateCurrentGroupId(item.id)}>
											{item.descrip}
										</MenuItem>
									))}
								</Select>
							</>
						)}
					</div>

					{isFreeText ? (
						<div className="form__shownProfessions">{professionsByQuery.length > 0 && <ProfessionsDataGrid professions={professionsByQuery} handleCheck={handleCheck} />}</div>
					) : (
						<div className="form__shownProfessions">{professions.length > 0 && <ProfessionsDataGrid professions={sortedProfessions} handleCheck={handleCheck} />}</div>
					)}

					<div className="form__chosenProfessions_horizontal-line"></div>
					{chosenProfessions?.length > 0 && (
						<div className="form__chosenProfessions">
							<p
								style={{
									fontSize: 16,
									textDecoration: 'underline',
									textUnderlineOffset: 5,
								}}
							>
								הפריטים שבחרתי: נבחרו {chosenProfessions.length} פריטים
							</p>
							{chosenProfessions?.map((item: any, index: any) => (
								<div key={item.id} className="form__chosenProfessions-item">
									<p className="chosenProfessions-item-descrip">
										{index + 1}. {item.descrip} ({item.profession})
									</p>
									<div className="form__chosenProfessions-expand-delete flex">
										<div className="link-wrapper flex column" aria-label={`לחץ לצפייה במפרט אחיד של פריט ${item.profession} ${item.descrip}`}>
											<a
												href={link}
												rel="noreferrer"
												target={'_blank'}
												aria-label={`לחץ לצפייה במפרט אחיד של פריט ${item.profession} ${item.descrip}`}
												onClick={() => createLink(item.profession)}
											>
												צפייה במפרט אחיד
											</a>
											<span>*נפתח באתר חיצוני</span>
										</div>
										<RemoveButton ariaLabel={`לחץ להסרת פריט ${item.profession} ${item.descrip}`} onClick={() => removeProfession(item)} isText={true} />
									</div>
								</div>
							))}
						</div>
					)}
					{globalRequiredFiles.length > 0 && (
						<div className="flex column" style={{ paddingTop: 10 }}>
							<span style={{ paddingLeft: 5 }} className="underline">
								להשלמת הבקשה תתבקש להעלות את הקבצים הבאים :
							</span>
							<span style={{ paddingLeft: 5 }} className="required">
								{businessDetailsFormText.requiredFieldsMessage}
							</span>
							<ul>
								{globalRequiredFiles.map((requiredFile: any, index: number) => (
									<li key={index}>
										{requiredFile.isRequired && <span style={{ color: '#B73D3D' }}>*</span>}
										{requiredFile.descrip}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
