import './component.style.scss';
export default function RemoveButton({ onClick, isText, ariaLabel }: any) {
	return (
		<button aria-label={ariaLabel} className="form__remove-owner-button" onClick={onClick}>
			<div className="form__deleteProfession"></div>
			<span
				className="form__remove-owner-button_text"
				style={{
					color: '#B73D3D',
					cursor: 'pointer',
					fontSize: 15,
				}}
			>
				{isText && 'הסר'}
			</span>
		</button>
	);
}
