// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__options {
  max-height: 70px;
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  list-style: none;
  padding: 0;
  margin: 0.2vh 0 10px 0;
  border-radius: 10px;
}
.select__options.shown {
  padding: 1%;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 10;
  position: absolute;
  background-color: white;
}
.select__options.hidden {
  display: none;
  visibility: hidden;
  pointer-events: none;
}

.autocomplete-legend {
  background-color: #fff;
  font-size: 16px;
  padding: 0 4px 0 0;
  z-index: 2;
}

.select__options li {
  cursor: pointer;
  padding-bottom: 5px;
  transition: 0.2s;
  border-radius: 10px;
  text-indent: 5px;
  padding-right: 5px;
}

.select__options li:hover {
  background-color: rgba(151, 151, 151, 0.678);
}

.autocomplete input::placeholder {
  font-size: 14px;
}

button:focus {
  border: 2px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/Select/component.style.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,UAAA;EACA,sBAAA;EACA,mBAAA;AACD;AACC;EACC,WAAA;EACA,0CAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;AACF;AAEC;EACC,aAAA;EACA,kBAAA;EACA,oBAAA;AAAF;;AAIA;EACC,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AADD;;AAIA;EACC,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AADD;;AAIA;EACC,4CAAA;AADD;;AAME;EACC,eAAA;AAHH;;AASC;EACC,uBAAA;AANF","sourcesContent":[".select__options {\n\tmax-height: 70px;\n\toverflow: scroll;\n\toverflow-x: hidden;\n\tscroll-behavior: smooth;\n\tlist-style: none;\n\tpadding: 0;\n\tmargin: 0.2vh 0 10px 0;\n\tborder-radius: 10px;\n\n\t&.shown {\n\t\tpadding: 1%;\n\t\tbox-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);\n\t\tdisplay: block;\n\t\tz-index: 10;\n\t\tposition: absolute;\n\t\tbackground-color: white;\n\t}\n\n\t&.hidden {\n\t\tdisplay: none;\n\t\tvisibility: hidden;\n\t\tpointer-events: none;\n\t}\n}\n\n.autocomplete-legend {\n\tbackground-color: #fff;\n\tfont-size: 16px;\n\tpadding: 0 4px 0 0;\n\tz-index: 2;\n}\n\n.select__options li {\n\tcursor: pointer;\n\tpadding-bottom: 5px;\n\ttransition: 0.2s;\n\tborder-radius: 10px;\n\ttext-indent: 5px;\n\tpadding-right: 5px;\n}\n\n.select__options li:hover {\n\tbackground-color: rgba(151, 151, 151, 0.678);\n}\n\n.autocomplete {\n\tinput {\n\t\t&::placeholder {\n\t\t\tfont-size: 14px;\n\t\t}\n\t}\n}\n\nbutton {\n\t&:focus {\n\t\tborder: 2px solid black;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
