import { FC } from 'react';
const params = new URLSearchParams(window.location.search);
const munParamValue = params.get('mun');

const AppFooter: FC = () => {
	const location = window.location.pathname;

	return (
		<div tabIndex={0} className="app-footer-container flex center" aria-label='V2.3 סיטי מערכות רישוי בע"מ'>
			V2.3 סיטי מערכות רישוי בע&quot;מ
			{location !== `/accessibility-statement?mun=${munParamValue}` && (
				<a className="accessability-link underline" href={`/accessibility-statement?mun=${munParamValue}`} target="_blank" rel="noopener noreferrer">
					הצהרת נגישות
				</a>
			)}
			-- המערכת הונגשה על ידי WEB-A
		</div>
	);
};

export default AppFooter;
