import { businessDetailsFormText } from '../../db/formHebrewText';
import '../../styles/Form.scss';
import '../../index.scss';
import './component.style.scss';
import Input from '../Input/Input';
import RadioButton from '../RadioButton';
import { useStore } from '../../store';
import { useEffect } from 'react';
import Autocomplete from '../Select/Select';
import '../../styles/MUI.scss';

export default function BusinessDetailsForm() {
	const { villagesValues, streets } = useStore().govDataContext;
	const { checkFormState } = useStore().activeStepContext;
	const {
		fetchFormTemplatesByIdProfessionsArray,
		businessDetailsRef,
		businessDetailsValues,
		businessDetailsErrors,
		handleChange,
		isBusinessDetailsFormValid,
		controlAddress,
		clearValues,
		getDropdownsOptions,
	} = useStore().businessDetailsFormContext;
	const { updateFormsContainer, updateFormsValidation, setIdRegion } = useStore().formsContainerContext;
	const villagesRealValues = villagesValues.map((v: any) => v.descrip);
	const isValidVillage = villagesRealValues.some((v: any) => v?.trim() === businessDetailsValues.address3?.trim());
	const currentStreets = streets.length > 0 ? streets : villagesRealValues;
	const isValidStreet = currentStreets.some((v: any) => v?.trim() === businessDetailsValues.address2?.trim());
	const { validityChanged } = useStore().formsContainerContext;

	const handleClickAddress = (val: string, address: string) => {
		controlAddress(val, address);
		const region = villagesValues.find((vil: any) => vil.descrip?.trim() === val?.trim());
		setIdRegion(region.id);
	};

	useEffect(() => {
		updateFormsContainer('businessDetails', businessDetailsValues);
		updateFormsValidation('businessDetails', isBusinessDetailsFormValid);
		checkFormState(isBusinessDetailsFormValid, 0);
		getDropdownsOptions();
	}, [businessDetailsValues]);

	useEffect(() => {
		fetchFormTemplatesByIdProfessionsArray();
	}, []);

	const showVillageError = (businessDetailsValues.address3?.length > 0 && !isValidVillage) || (!businessDetailsValues.address3?.length && validityChanged);
	const showStreetError = (businessDetailsValues.address2?.length > 0 && !isValidStreet) || (!businessDetailsValues.address2?.length && validityChanged);

	return (
		<form className="form" ref={businessDetailsRef}>
			<div className="form-top-wrapper flex column" style={{ gap: 15 }}>
				<h1 className="form__title" style={{ width: '105px' }}>
					{businessDetailsFormText.title}
				</h1>
				<div className="business-details-form__top flex">
					<div className="flex column">
						<button type="button" className="reset-button underline" onClick={() => clearValues()}>
							אפס טופס
						</button>
					</div>
					<div className="form__instructions flex column">
						<span>{businessDetailsFormText.requiredFieldsMessage}</span>
					</div>
				</div>
			</div>

			<div className="form__main flex column">
				<div className="form__container__row first-row" style={{ gap: '35px' }}>
					<Input
						legend={businessDetailsFormText.businessName}
						onChange={handleChange}
						isRequired={true}
						value={businessDetailsValues.profname}
						errorMessage={businessDetailsErrors.profname}
						name="profname"
						type="text"
						placeholder={''}
						min={0}
						max={20}
						pattern={undefined}
					/>
					<Input
						legend={businessDetailsFormText.businessIdentifier}
						isRequired={true}
						min={9}
						max={9}
						pattern="[0-9]*"
						onChange={handleChange}
						value={businessDetailsValues.osek}
						errorMessage={businessDetailsErrors.osek}
						name="osek"
						type="text"
						placeholder={''}
					/>
				</div>
				<div>
					<h2 className="form__title_small">כתובת :</h2>
					<div className="address-grid-container flex">
						<div className="address-right-grid ">
							<div>
								<Autocomplete
									id={'address2-dropdown'}
									formNumber={1}
									options={streets.length > 0 ? streets : villagesRealValues}
									name={'address2'}
									errorMessage={showStreetError ? 'אנא בחר רחוב מהרשימה' : ''}
									value={businessDetailsValues.address2}
									handleChange={handleChange}
									handleClickProp={controlAddress}
									legend={'רחוב'}
									isRequired={true}
								/>
							</div>
							<Input
								legend={businessDetailsFormText.homeNumber}
								isRequired={true}
								onChange={handleChange}
								value={businessDetailsValues.address1}
								errorMessage={businessDetailsErrors.address1}
								name="address1"
								type="number"
								placeholder={''}
								min={0}
								max={5}
								pattern="[0-9]*"
							/>
							<div className="full-width">
								<Autocomplete
									id={'address3-dropdown'}
									formNumber={1}
									options={villagesRealValues}
									name={'address3'}
									errorMessage={showVillageError ? 'אנא בחר יישוב או איזור מהרשימה' : ''}
									value={businessDetailsValues.address3}
									handleChange={handleChange}
									handleClickProp={handleClickAddress}
									legend={'יישוב/איזור'}
									isRequired={true}
								/>
							</div>
						</div>
						<div className="address-middle-grid">
							<div className="item1">
								<Input
									legend={'קומה'}
									onChange={handleChange}
									value={businessDetailsValues.floor_number}
									name="floor_number"
									type="number"
									isRequired={false}
									placeholder={''}
									errorMessage={businessDetailsErrors.floor_number}
									maxNumber={50}
									min={0}
									max={2}
									pattern="[0-9]*"
								/>
							</div>
							<div className="item2">
								<Input
									legend={businessDetailsFormText.shopNumber}
									onChange={handleChange}
									value={businessDetailsValues.buildingNumber}
									name="buildingNumber"
									type="number"
									isRequired={false}
									placeholder={''}
									maxNumber={20000}
									errorMessage={businessDetailsErrors.buildingNumber}
									min={0}
									max={0}
									pattern="[0-9]*"
								/>
							</div>
							<div className="item3">
								<Input
									legend={businessDetailsFormText.postalCode}
									onChange={handleChange}
									value={businessDetailsValues.zipcode}
									name="zipcode"
									type="number"
									isRequired={false}
									placeholder={''}
									errorMessage={''}
									min={0}
									max={0}
									pattern="[0-9]*"
								/>
							</div>
							<div className="item4">
								<Input
									legend={businessDetailsFormText.deliveryCode}
									value={businessDetailsValues.post}
									onChange={handleChange}
									name="post"
									type="number"
									isRequired={false}
									placeholder={''}
									errorMessage={''}
									min={0}
									max={12}
									pattern="[0-9]*"
								/>
							</div>
						</div>
						<div className="address-left-grid">
							<div className="full-width">
								<Input
									legend={businessDetailsFormText.propertyNumber}
									name="neches"
									onChange={handleChange}
									value={businessDetailsValues.neches}
									type="decimal"
									isRequired={false}
									placeholder={''}
									errorMessage={''}
									min={0}
									max={0}
									pattern="[0-9]*"
								/>
							</div>
							<Input
								legend={businessDetailsFormText.chunk}
								onChange={handleChange}
								value={businessDetailsValues.gush}
								name="gush"
								type="decimal"
								isRequired={false}
								placeholder={''}
								errorMessage={''}
								min={0}
								max={24}
								pattern="[0-9]*"
							/>
							<Input
								legend={businessDetailsFormText.lot}
								onChange={handleChange}
								value={businessDetailsValues.helka}
								name="helka"
								type="decimal"
								isRequired={false}
								placeholder={''}
								errorMessage={''}
								min={0}
								max={24}
								pattern="[0-9]*"
							/>
							<Input
								legend={'מגרש'}
								onChange={handleChange}
								value={businessDetailsValues.area}
								name="area"
								type="decimal"
								isRequired={false}
								placeholder={''}
								errorMessage={''}
								min={0}
								max={24}
								pattern="[0-9]*"
							/>
						</div>
					</div>
				</div>
				<h2 className="form__title_small">תיאור העסק:</h2>
				<textarea
					className="textarea"
					value={businessDetailsValues.profession_descripl}
					onChange={handleChange}
					name="profession_descripl"
					placeholder=""
					aria-label="תיאור העסק"
					minLength={0}
					maxLength={500}
				/>
			</div>
		</form>
	);
}
