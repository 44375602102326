// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiOutlinedInput-root {
  display: flex !important;
  height: 43px !important;
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 !important;
}

.MuiFormLabel-root {
  direction: rtl !important;
  top: 7px !important;
}

@media screen and (max-width: 600px) {
  .MuiStepper-root {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .MuiStepLabel-label {
    display: none !important;
  }
}

.date-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.date-input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.MuiTimePickerToolbar-hourMinuteLabel,
.MuiPickersArrowSwitcher-root {
  flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/styles/MUI.scss"],"names":[],"mappings":"AAAA;EACC,wBAAA;EACA,uBAAA;AACD;AACC;EACC,qBAAA;AACF;;AAGA;EACC,yBAAA;EACA,mBAAA;AAAD;;AAIC;EADD;IAEE,sBAAA;EAAA;AACF;;AAIC;EADD;IAEE,wBAAA;EAAA;AACF;;AAGA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;AAAD;;AAGA;EACC,eAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AAAD;;AAGA;;EAEC,2BAAA;AAAD","sourcesContent":[".MuiOutlinedInput-root {\n\tdisplay: flex !important;\n\theight: 43px !important;\n\n\t.MuiAutocomplete-input {\n\t\tpadding: 0 !important;\n\t}\n}\n\n.MuiFormLabel-root {\n\tdirection: rtl !important;\n\ttop: 7px !important;\n}\n\n.MuiStepper-root {\n\t@media screen and (max-width: 600px) {\n\t\twidth: 100% !important;\n\t}\n}\n\n.MuiStepLabel-label {\n\t@media screen and (max-width: 600px) {\n\t\tdisplay: none !important;\n\t}\n}\n\n.date-input-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-bottom: 1rem;\n}\n\n.date-input {\n\tpadding: 0.5rem;\n\tfont-size: 1rem;\n\tborder: 1px solid #ccc;\n\tborder-radius: 4px;\n}\n\n.MuiTimePickerToolbar-hourMinuteLabel,\n.MuiPickersArrowSwitcher-root {\n\tflex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
