import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

interface BasicModalProps {
	isOpen: boolean;
	closeModal: () => void;
	image: string;
}

export default function BasicModal({ isOpen, closeModal, image }: BasicModalProps) {
	const [imageSource, setImageSource] = useState('');
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	function dataUrlToImageSource(dataUrl: string): string {
		return `data:${dataUrl.split(',')[0].split(':')[1].split(';')[0]};base64,${dataUrl.split(',')[1]}`;
	}

	useEffect(() => {
		if (image && image.length > 1 && isOpen) {
			const fileType = image.split(',')[0].split(':')[1].split(';')[0];
			if (fileType.startsWith('image/')) {
				const realImage = dataUrlToImageSource(image);
				setImageSource(realImage);
			} else if (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
				const newWindow = window.open();
				if (newWindow) {
					newWindow.document.write(`<iframe src="${image}" width="100%" height="100%" style="border:none;"></iframe>`);
					closeModal();
				}
			}
		}
	}, [image, isOpen, closeModal]);

	return (
		<div>
			<Modal open={open && !!imageSource} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>{imageSource ? <img src={imageSource} alt="" height={450} width={320} /> : null}</Box>
			</Modal>
		</div>
	);
}
