import '../index.scss';

export default function RadioButton({ name, value, labelText, isRequired, onChange, isChecked, extraClass, tabIndex, ariaLabel, disabled }: any) {
	return (
		<label className="radio-button-label">
			{labelText}
			<input
				tabIndex={tabIndex}
				aria-label={ariaLabel?.length > 0 ? ariaLabel : ''}
				className={`radio-button ${extraClass}`}
				type="radio"
				name={name}
				value={value}
				required={!!isRequired}
				onChange={onChange}
				checked={isChecked}
				disabled={disabled}
			/>
		</label>
	);
}
