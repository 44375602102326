import { useEffect } from 'react';
import { useState } from 'react';
import { govService } from '../../api/GovService';
import mainService from '../../api/MainService';
const villageName = 'שם_ישוב';
const ident = 'סמל_ישוב';
const streetName = 'שם_רחוב';

export const useGovData = () => {
	const [lamas_code, setLamasCode] = useState('');
	const [chosenGlobalVillage, setChosenGlobalVillage] = useState('');
	const [globalStreets, setGlobalStreets] = useState([]);
	const [globalVillages, setGlobalVillages] = useState([]);
	const [currentVillage, setCurrentVillage] = useState('');
	const [villagesValues, setVillagesArray] = useState<any[]>([]);
	const [streets, setStreets] = useState<any[]>([]);

	useEffect(() => {
		if (chosenGlobalVillage && chosenGlobalVillage.length > 0) {
			govService
				.getVillages()
				.then((res) => {
					const chosenVillageObj = res.result.records.find((obj: any) => obj[villageName] === chosenGlobalVillage);
					const lamas_code = chosenVillageObj[ident];

					govService.getGlobalStreetsByChosenVillage(lamas_code).then((res) => {
						if (res) {
							if (res.result.records) {
								const { records } = res.result;
								const streetsValues = records.map((street: any) => street[streetName]);
								setGlobalStreets(streetsValues);
							}
						}
					});
				})
				.catch((err) => console.log(err));
		}
	}, [chosenGlobalVillage]);

	useEffect(() => {
		govService
			.getVillages()
			.then((res) => {
				const cleanRes = res.result.records.map((val: any) => val[villageName]);
				setGlobalVillages(cleanRes);
			})
			.catch((err) => console.log(err));
	}, []);

	const updateCurrentVillage = (village: any) => {
		setCurrentVillage('');
		setCurrentVillage(village);
	};

	useEffect(() => {
		mainService
			.getStaticData('regions')
			.then((res) => {
				if (res && Array.isArray(res)) {
					const trimmedDescriptions = res
						.filter((item) => item.descrip !== null)
						.map((item) => ({
							...item,
							descrip: item.descrip.trim(),
						}));

					setVillagesArray(trimmedDescriptions);
				}
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		mainService.getMun().then((currentMun) => {
			if (currentMun) {
				setLamasCode(currentMun.lamas_code);
			}
		});
	}, []);

	useEffect(() => {
		setStreets([]);
		govService
			.getStreetByCurrentVillage(lamas_code)
			.then((res) => {
				if (res.result.records) {
					const { records } = res.result;
					const streetsValues = records.map((street: any) => street[streetName]).filter((item: any) => item !== currentVillage.trim());
					setStreets(streetsValues);
				} else {
					setStreets(villagesValues);
				}
			})
			.catch((err) => console.log(err));
	}, [currentVillage, lamas_code, villagesValues]);

	return {
		setChosenGlobalVillage,
		streets,
		currentVillage,
		globalVillages,
		globalStreets,
		updateCurrentVillage,
		villagesValues,
	};
};
