import { useState, useEffect, useRef } from 'react';
import { AiOutlinePhone } from 'react-icons/ai';
import PreviewMunInfo from '../PreviewMunInfo/PreviewMunInfo';
import './style.scss';
const ContactUsInfo = () => {
	const [showContactInfo, setShowContactInfo] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const contactInfoRef: any = useRef(null);
	const buttonRef: any = useRef(null);
	const munData = JSON.parse(sessionStorage.getItem('mun') ?? '{}');

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (contactInfoRef.current && !contactInfoRef.current.contains(event.target) && !buttonRef.current?.contains(event.target) && showContactInfo) {
				setShowContactInfo(false);
				setShowOverlay(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showContactInfo]);

	const toggleContactInfo = () => {
		setShowContactInfo(!showContactInfo);
		setShowOverlay(!showOverlay);
	};

	return (
		<div className="contact-us-container-wrapper">
			<button tabIndex={500} className="contact-us-container flex center" aria-label="לחץ לצפייה בפרטי קשר הרשות" onClick={toggleContactInfo} ref={buttonRef}>
				<AiOutlinePhone />
			</button>
			{showOverlay && <div className="contact-us-overlay" onClick={toggleContactInfo} />}
			{showContactInfo && munData && (
				<div
					className="contact-us-info-wrapper"
					ref={contactInfoRef}
					style={{
						position: 'absolute',
						bottom: '110%',
						right: '0',
						zIndex: 101,
					}}
				>
					<PreviewMunInfo mun={munData} />
				</div>
			)}
		</div>
	);
};

export default ContactUsInfo;
