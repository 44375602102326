import { FiAtSign, FiPhone } from 'react-icons/fi';
import { LiaFaxSolid } from 'react-icons/lia';
import { TfiWorld } from 'react-icons/tfi';
import { MdPhoneAndroid } from 'react-icons/md';

export const MunIconList = [
	{ icon: FiPhone, name: 'telephon1', label: 'טלפון' },
	{ icon: MdPhoneAndroid, name: 'pikuha_telephon', label: 'נייד' },
	{ icon: LiaFaxSolid, name: 'fax', label: 'פקס' },
	{ icon: FiAtSign, name: 'email', label: 'דואר אלקטרוני' },
	{ icon: TfiWorld, name: 'web_site', label: 'אתר' },
];
