import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export abstract class Api {
	protected async axiosRequest(url: string, config: AxiosRequestConfig, withAuthorization: boolean = true) {
		try {
			const token = new URL(window.location.href).searchParams.get('token');
			// Conditionally add Authorization header
			const headers = withAuthorization ? { 'Content-Type': 'application/json', ...config.headers, Authorization: `Bearer ${token}` } : { 'Content-Type': 'application/json', ...config.headers };

			const res: AxiosResponse = await axios.request({ url, ...config, headers });
			return res.data;
		} catch (error: any) {
			if (axios.isAxiosError(error) && error.response) {
				return Promise.reject(`Error: ${error.response.status} - ${error.response.data}`);
			}
			return Promise.reject(`Error: ${error.message || 'Unknown error occurred'}`);
		}
	}
}
