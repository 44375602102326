import '../index.scss';

export default function InFormCheckBox({ id, name, onChange, labelText, isDisabled, checkState, isRequired, tabIndex }: any) {
	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			onChange(e);
		}
	};

	return (
		<label className="checkbox-label">
			{labelText}
			<input
				tabIndex={tabIndex}
				id={id}
				className="checkbox"
				type="checkbox"
				name={name}
				value={checkState ? '1' : '0'}
				onChange={(e) => onChange(e)}
				onKeyDown={handleKeyPress}
				checked={checkState}
				disabled={isDisabled}
				required={isRequired}
			/>
		</label>
	);
}
