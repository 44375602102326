import CheckBox from '../CheckBox';
import './component.style.scss';
import { IProfessionsDataGrid } from '../../ts/interfaces';

export default function ProfessionsDataGrid({ professions, handleCheck }: IProfessionsDataGrid) {
	return (
		<>
			<table>
				<tbody>
					<tr className="table__row">
						<th></th>
						<th>פריט</th>
						<th>מהות</th>
					</tr>
					{professions.map((prof: any) => {
						const isDisabledByMun = prof.internet_show_flag;
						return (
							<tr key={prof.id} className={`${isDisabledByMun && 'disabled-row'}`}>
								<td className="table__data_checkbox flex center">
									{!isDisabledByMun && (
										<label htmlFor={`checkbox-${prof.id}`} aria-label={`בחר את פריט ${prof.profession?.trim()} ${prof.descrip?.trim()}`}>
											<CheckBox
												ariaLabel={`בחר את פריט ${prof.profession?.trim()} ${prof.descrip?.trim()}`}
												id={`checkbox-${prof.id}`}
												onChange={(e: any) => handleCheck(e, prof)}
												isChecked={prof.isChecked}
												labelText={''}
												isDisabled={isDisabledByMun}
											/>
										</label>
									)}
								</td>
								<td className="table__data">{prof.profession}</td>
								<td className="table__data">{prof.descrip}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
