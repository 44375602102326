export const businessDetailsFormText = {
	requiredSign: '*',
	requiredFieldsMessage: '* שדות המסומנים בכוכבית הם שדות חובה',
	title: 'פרטי העסק',
	radioButtonCompany: 'אגודה שיתופית/שותפות/חברה/עמותה',
	radioButtonPrivate: 'עוסק מורשה/פטור',
	businessName: 'שם העסק (מסחרי)',
	businessIdentifier: 'ח.פ/מלכ”ר/תעודת זהות',
	manager: 'מנהל',
	phone: 'טלפון',
	mobilePhone: 'נייד',
	email: 'מייל',
	area: 'ישוב/אזור',
	street: 'רחוב',
	homeNumber: 'מספר בית',
	shopNumber: 'מספר חנות',
	businessArea: 'שטח מבונה', //agra3
	totalArea: 'שטח כולל', //surface
	postalCode: 'מיקוד',
	deliveryCode: 'תא דואר',
	chunk: 'גוש',
	lot: 'חלקה',
	numberOfEmployees: 'מספר עובדים',
	numberOfSeats: 'תפוסה',
	propertyNumber: 'מספר נכס',
	acceptEmailMessages: 'אני מסכים לקבל דיוור במייל מרשות הרישוי על סמך תקנה 39',
	acceptSmsMessages: 'אני מסכים לקבל דיוור במסרון מרשות הרישוי על סמך תקנה 39',
};

export const ownersFormText = {
	title: 'פרטי בעלים ומיופה כוח',
	privateNameOrCompany: 'שם משפחה/חברה',
	familyNameOrOwner: 'שם פרטי/בעלים',
	identifier: 'מזהה',
	setAsDeliveryAddressText: 'הגדר כתובת זו ככתובת למשלוח דואר',
	addAnotherOwnerText: 'הוספת בעלים נוספים',
};

export const uploadFilesFormText = {
	firstBlockTitle: 'תצהיר לעניין חוק תכנון ובנייה',
	firstBlockRadioButtonFirst: '.ניתן היתן לפי חוק התכנון והבנייה, התשכ”ה -1965(להלן - חוק התכנון והבנייה) לבנייתו של המקום בו עתיד לפעול העסק',
	firstBlockRadioButtonSecond: '.לא הצלחתי , בשקידה סבירה, לאתר ההיתר האמור שניתן למקום',
	firstBlockCheckBox: '.לא ביצעתי בעסק עבודה הטעונה היתר לפי חוק התכנון והבנייה, בלא היתר כאמור',
	summaryTitle: 'מסמכים נדרשים וסיכום',
	summaryRow1: 'בחרת להגיש בקשה לרישיון עסק עבור פריטי העיסוק',
	summaryRows: [{ text: 'על מנת להתחיל בתהליך עליך לעלות את מסמכי החובה המסומנים בכוכבית : ' }],
};
