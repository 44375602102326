import { useState } from 'react';
import { getFormDataInLocalStorage, setFormDataInLocalStorage } from '../../utils/localStorage';

const formsMock = {
	isMifrat: false,
	deliveryAddress: {},
	businessDetails: {},
	owners: [],
	chosenProfessions: [],
	formSummary: {},
	idregion: 1,
	platformFlag: 'openRequest',
	loginIdent: '',
};

export const useFormsContainer = () => {
	const [numero, setNumero] = useState('');
	const [formSummaryPdf, setPdf] = useState<Array<number>>([]);
	const [isLoading, setLoading] = useState(false);
	const [dossierCreated, setDossierCreated] = useState<boolean | undefined>(undefined);
	const [globalValidation, setValidation] = useState({
		businessDetails: false,
		owners: false,
		chosenProfessions: false,
		formSummary: false,
	});
	const [forms, setForms] = useState(getFormDataInLocalStorage('forms', formsMock) as typeof formsMock);
	const [validityChanged, setValidityChanged] = useState(0);

	const updateFormsContainer = (formToUpdate: string, formValues: any) => {
		const updatedForms = {
			...forms,
			[formToUpdate]: formValues,
		};
		setForms(updatedForms);
		setFormDataInLocalStorage(updatedForms, 'forms');
	};

	const updateFormsValidation = (formToUpdate: string, formState: boolean) => {
		setValidation((current) => ({
			...current,
			[formToUpdate]: formState,
		}));
		localStorage.setItem('formsValidation', JSON.stringify(globalValidation));
	};

	const updateDeliveryAddress = (obj: any) => {
		setForms((current) => ({
			...current,
			deliveryAddress: obj,
		}));
	};

	const updateMifratState = (state: boolean) => {
		setForms({
			...forms,
			isMifrat: state,
		});
	};

	const dossierCreatedSuccessfully = () => {
		setDossierCreated(true);
	};

	const dossierDidNotCreatedSuccessfully = () => {
		setDossierCreated(false);
	};

	const setIdRegion = async (id: number) => {
		const loginIdent = new URL(window.location.href).searchParams.get('ident');
		setForms({ ...forms, idregion: id, loginIdent: loginIdent ? loginIdent.split('').reverse().join('') : '' });
	};

	return {
		setForms,
		forms,
		setNumero,
		setIdRegion,
		numero,
		dossierCreated,
		formSummaryPdf,
		setPdf,
		setLoading,
		isLoading,
		globalValidation,
		dossierCreatedSuccessfully,
		dossierDidNotCreatedSuccessfully,
		updateMifratState,
		updateDeliveryAddress,
		updateFormsContainer,
		updateFormsValidation,
		validityChanged,
		setValidityChanged,
	};
};
