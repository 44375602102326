// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  direction: ltr !important;
  height: 55vh;
  overflow: auto;
  padding: 35px 30px 15px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.form-container .form {
  width: 65vw;
}
@media (max-width: 1000px) {
  .form-container {
    height: 50vh;
    width: 85vw;
    margin-bottom: 20px;
    align-items: normal;
  }
  .form-container .form {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .form-container {
    padding: 10px;
    height: auto;
    overflow-x: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FormContainer/component.style.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,YAAA;EACA,cAAA;EACA,4BAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;AACD;AACC;EACC,WAAA;AACF;AAEC;EAbD;IAcE,YAAA;IACA,WAAA;IACA,mBAAA;IACA,mBAAA;EACA;EACA;IACC,WAAA;EACD;AACF;AAEC;EAxBD;IAyBE,aAAA;IACA,YAAA;IACA,kBAAA;EACA;AACF","sourcesContent":[".form-container {\n\tdirection: ltr !important;\n\theight: 55vh;\n\toverflow: auto;\n\tpadding: 35px 30px 15px 30px;\n\tmargin: 0 auto;\n\tdisplay: flex;\n\tjustify-content: center;\n\n\t.form {\n\t\twidth: 65vw;\n\t}\n\n\t@media (max-width: 1000px) {\n\t\theight: 50vh;\n\t\twidth: 85vw;\n\t\tmargin-bottom: 20px;\n\t\talign-items: normal;\n\n\t\t.form {\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t@media (max-width: 600px) {\n\t\tpadding: 10px;\n\t\theight: auto;\n\t\toverflow-x: hidden;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
