import { Stepper, Step, StepLabel } from '@mui/material';
import { StepConnector, stepConnectorClasses, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useStore } from '../store';
import '../index.scss';
import { useEffect, useState } from 'react';

export default function CustomStepper() {
	const { currentStep, setCurrentStep } = useStore().activeStepContext;
	const globalValidation = JSON.parse(localStorage.getItem('formsValidation') as unknown as any);
	const [isStepCompleted, setIsStepCompleted] = useState([true, false, false, false, false, false]);
	const { dossierCreated } = useStore().formsContainerContext;
	const isValidStep = (index: number) => {
		if (!globalValidation) return true;
		if (index === 0) return true;
		const prevStepKey = Object.keys(globalValidation)[index - 1] as keyof typeof globalValidation;
		return globalValidation[prevStepKey];
	};

	useEffect(() => {
		updateIsStepCompleted();
	}, [currentStep]);
	const steps = ['פריטי עיסוק', 'פרטי בעלים ומיופי כוח', 'פרטי העסק', 'פרטים נוספים על העסק', 'תצהירים ושיוך למסלולים', 'מסמכים נדרשים וסיכום'];
	const handleStepClick = (index: number) => {
		if (isValidStep(index)) {
			setCurrentStep(index);
		}
	};

	const updateIsStepCompleted = () => {
		const newIsStepCompleted = steps.map((_, index) => index <= currentStep);
		setIsStepCompleted(newIsStepCompleted);
	};

	return !dossierCreated ? (
		<Stepper key={currentStep} style={{ direction: 'rtl' }} nonLinear connector={<ColorLibConnector />} alternativeLabel orientation="horizontal" activeStep={currentStep} sx={{ width: '60vw' }}>
			{steps.map((step, index) => {
				return (
					<Step
						key={step}
						onClick={() => handleStepClick(index)}
						disabled={!isStepCompleted[index]}
						sx={{
							cursor: isValidStep(index) ? 'pointer' : 'not-allowed',
						}}
					>
						<StepLabel StepIconComponent={(props) => <ColorLibStepIcon {...props} currentStep={currentStep} isActive={currentStep === index} />}>
							<span>{step}</span>
						</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	) : (
		<div></div>
	);
}

function ColorLibStepIcon(props: any) {
	const { active, icon, currentStep } = props;
	const completed = icon < currentStep + 1;
	const disabled = icon > currentStep + 1;

	return (
		<ColorLibStepIconRoot ownerState={{ completed, active, disabled }}>
			<Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>{props.icon}</Typography>
		</ColorLibStepIconRoot>
	);
}
const ColorLibConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 20,
		left: 'calc(50% + 16px)',
		right: 'calc(-50% + 16px)',
	},
	[`&.${stepConnectorClasses.disabled}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#c4c4c4',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: '#007aa5',
		borderTopWidth: 3,
		borderLeftWidth: 5,
	},
}));
const ColorLibStepIconRoot = styled('div')(({ ownerState }: any) => ({
	backgroundColor: '#fff',
	zIndex: 1,
	color: '#c4c4c4',
	width: 38,
	height: 38,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	border: '1px solid #c4c4c4',
	alignItems: 'center',
	transition: 'all 0.3s ease',
	...(ownerState.active && {
		border: '1px solid #007aa5',
		color: '#007aa5',
		boxShadow: '2px 0px 9px #08d1b55e',
	}),
	...(ownerState.completed && {
		border: '1px solid #007aa5',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		color: '#007aa5',
	}),
	...(ownerState.completed && {
		backgroundColor: '#007aa5',
		border: '1px solid #007aa5',
		color: '#007aa5',
	}),
}));
