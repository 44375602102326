import { useEffect } from 'react';
import { useStore } from '../store';
import OwnersForm from './OwnersForm/OwnersForm';

export default function OwnersFormContainer() {
	const { owners } = useStore().ownersFormContainerContext;
	const { updateFormsContainer, updateFormsValidation } = useStore().formsContainerContext;
	const { checkFormState } = useStore().activeStepContext;
	const thereIsInvalidOwner = owners.some((owner: any) => owner.isValid === false);

	const formsState = !thereIsInvalidOwner;

	useEffect(() => {
		updateFormsContainer('owners', owners);
		updateFormsValidation('owners', formsState);
		checkFormState(formsState, 2);
	}, [owners]);

	return (
		<section className="step-two-container">
			{owners.map((owner: any, index: number) => {
				return <OwnersForm key={owner.id} id={owner.id} ownersArrayIndex={index} />;
			})}
		</section>
	);
}
