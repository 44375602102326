import { useRef, useState, useEffect } from 'react';
import { getFormBooleanValue, getFormDataInLocalStorage, setFormBooleanValue, setFormDataInLocalStorage } from '../../utils/localStorage';
import { IbusinessDetails } from '../../ts/interfaces';
import mainService from '../../api/MainService';
import { businessDetailsMock } from '../../mocks/businessDetailsFormMock';
interface handleFormTemplateQuestionChangeParams {
	formId: string;
	questionId: string;
	value: any;
	sectionId: number;
	questionType: string;
	checked?: boolean;
}
export function useBusinessDetailsForm() {
	const businessDetailsRef = useRef<HTMLInputElement | null>(null);
	const [businessDetailsValues, setBusinessDetailsValues] = useState(businessDetailsMock);
	const [businessDetailsErrors, setBusinessDetailsErrors] = useState({});
	const [dropdownsOptions, setDropdownsOptions] = useState({});
	const [placeKindData, setPlaceKindData] = useState();
	const [isBusinessDetailsFormValid, setIsBusinessDetailsFormValid] = useState(false);
	const [formTemplates, setFormTemplates] = useState<any[]>([]);
	const [formTemplateAnswers, setFormTemplateAnswers] = useState<{ [key: string]: any }>([]);
	const localStorageProfessions = localStorage.getItem('chosenProfessions');

	const clearValues = () => {
		setBusinessDetailsValues(businessDetailsMock);
		localStorage.removeItem('businessDetails');
	};

	const clearExtraDetailsValues = () => {
		const data: Partial<IbusinessDetails> | null = JSON.parse(localStorage.getItem('businessDetails') ?? 'null');
		if (data) {
			const keysToRemove = ['agra3', 'surface', 'area', 'ceilling_height', 'storage_height', 'rooms', 'number_of_floors', 'workers', 'seats', 'number_of_beds', 'kibolet'];
			const filteredData = Object.entries(data).reduce((acc: any, [key, value]) => {
				if (!keysToRemove.includes(key)) {
					acc[key] = value;
				}
				return acc;
			}, {} as Partial<IbusinessDetails>);
			setBusinessDetailsValues(filteredData as IbusinessDetails);
			localStorage.setItem('businessDetails', JSON.stringify(filteredData));
		}
	};

	useEffect(() => {
		const initialFormState = getFormDataInLocalStorage('businessDetails', businessDetailsMock);
		const initialBooleanValue = getFormBooleanValue('businessDetails');
		setIsBusinessDetailsFormValid(initialBooleanValue);
		setBusinessDetailsValues(initialFormState);
	}, []);

	const handleChange = (event: any) => {
		let target;
		let value;
		if (event.type === 'click') {
			target = event.target;
			value = target.value || '';
		} else {
			target = event.currentTarget;
			value = target.value;
		}
		const name = target.name || '';
		if (name === 'takana_39_email_flag' || name === 'takana_39_sms_flag') {
			value = parseInt(value);
			value = value === 0 ? '1' : '0';
		}

		const updatedBusinessDetailsFormValues = {
			...businessDetailsValues,
			[name]: value,
		};
		setBusinessDetailsValues(updatedBusinessDetailsFormValues);

		const form = document.querySelector('form');
		setBusinessDetailsErrors({
			...businessDetailsErrors,
			...(name !== 'osek' && { [name]: target.validationMessage }),
		});

		setIsBusinessDetailsFormValid(form?.checkValidity() ?? false);
		setFormDataInLocalStorage(updatedBusinessDetailsFormValues, 'businessDetails');
		setFormBooleanValue('businessDetailsBool', isBusinessDetailsFormValid);
		setFormBooleanValue(
			'extraDetailsFormValid',
			(updatedBusinessDetailsFormValues.agra3?.toString()?.trim().length ?? 0) > 0 && (updatedBusinessDetailsFormValues.surface?.toString()?.trim()?.length ?? 0) > 0
		);
	};

	const handleDateChange = (key: string, value: Date) => {
		const updatedBusinessDetailsFormValues = {
			...businessDetailsValues,
			[key]: value,
		};
		setBusinessDetailsValues(updatedBusinessDetailsFormValues);
	};

	const controlAddress = (value: string, addressToChange: string) => {
		setBusinessDetailsValues({
			...businessDetailsValues,
			[addressToChange]: value,
		});
	};

	const getDropdownsOptions = async () => {
		if (placeKindData) return;
		try {
			const res = await mainService.getStaticData('placeKind');
			if (res) {
				const filteredRes = res.filter((item: any) => item.list_kind === 0);
				setDropdownsOptions(filteredRes);
				setPlaceKindData(res);
			}
		} catch (err) {}
	};

	const fetchFormTemplatesByIdProfessionsArray = async () => {
		try {
			if (!localStorageProfessions) return;
			const chosenProfessions = JSON.parse(localStorageProfessions);
			if (!Array.isArray(chosenProfessions)) return;
			setFormTemplates([]);
			const chosenProfessionsIds = chosenProfessions.map((prof) => prof.id);
			const formTemplatesByChosenProfessions = await mainService.getFormTemplatesByProfessionIds(chosenProfessionsIds);

			setFormTemplates(formTemplatesByChosenProfessions);
		} catch (error) {
			console.error('Error fetching form templates by profession IDs:', error);
		}
	};

	const handleFormTemplateQuestionChange = ({ formId, questionId, value, sectionId, questionType, checked }: handleFormTemplateQuestionChangeParams) => {
		let answer: any = {};

		if (['short-text', 'long-text', 'email', 'decimal', 'number', 'date', 'time'].includes(questionType)) {
			answer = {
				questionId,
				sectionId,
				answer_text: typeof value === 'string' ? value : value?.toString(),
			};
		} else if (['dropdown', 'radio-buttons'].includes(questionType)) {
			answer = {
				questionId,
				sectionId,
				answer_option_id: value?.toString(),
			};
		} else if (questionType === 'checkboxes') {
			setFormTemplateAnswers((prev: any[]) => {
				const existingFormIndex = prev.findIndex((form: { formId: string }) => form.formId === formId);

				if (existingFormIndex !== -1) {
					const existingAnswers = prev[existingFormIndex].answers || [];

					let updatedAnswers;
					if (checked) {
						// Add a new record for the checked option
						updatedAnswers = [
							...existingAnswers,
							{
								questionId,
								sectionId,
								answer_option_id: value?.toString(),
							},
						];
					} else {
						// Remove the record for the unchecked option
						updatedAnswers = existingAnswers.filter((answer: any) => !(answer.questionId === questionId && answer.answer_option_id === value?.toString()));
					}

					return [
						...prev.slice(0, existingFormIndex),
						{
							...prev[existingFormIndex],
							answers: updatedAnswers,
						},
						...prev.slice(existingFormIndex + 1),
					];
				} else {
					return [
						...prev,
						{
							formId,
							answers: [
								{
									questionId,
									sectionId,
									answer_option_id: value?.toString(),
								},
							],
						},
					];
				}
			});

			return;
		}

		setFormTemplateAnswers((prev: any[]) => {
			const existingFormIndex = prev.findIndex((form: { formId: string }) => form.formId === formId);

			if (existingFormIndex !== -1) {
				const existingAnswers = prev[existingFormIndex].answers || [];
				const existingAnswerIndex = existingAnswers.findIndex((ans: any) => ans.questionId === questionId);

				let updatedAnswers;
				if (existingAnswerIndex !== -1) {
					updatedAnswers = [...existingAnswers.slice(0, existingAnswerIndex), answer, ...existingAnswers.slice(existingAnswerIndex + 1)];
				} else {
					updatedAnswers = [...existingAnswers, answer];
				}

				return [
					...prev.slice(0, existingFormIndex),
					{
						...prev[existingFormIndex],
						answers: updatedAnswers,
					},
					...prev.slice(existingFormIndex + 1),
				];
			} else {
				return [
					...prev,
					{
						formId,
						answers: [answer],
					},
				];
			}
		});
	};

	const findDependencyAnswer = (formId: string, dependencyQuestionId: string, formTemplateAnswers: any[]) => {
		const formAnswers = formTemplateAnswers.find((form: { formId: string }) => form.formId === formId)?.answers;
		return formAnswers?.find((ans: any) => ans.questionId === dependencyQuestionId);
	};

	const findDependencyQuestion = (dependencyQuestionId: string, formTemplates: any[]) => {
		const sections = formTemplates.flatMap((formTemplate: any) => formTemplate.sections);
		const questions = sections.flatMap((section: any) => section.questions);
		return questions.find((q: any) => q.id === dependencyQuestionId);
	};

	return {
		placeKindData,
		setIsBusinessDetailsFormValid,
		setBusinessDetailsValues,
		clearValues,
		businessDetailsRef,
		controlAddress,
		businessDetailsValues,
		handleChange,
		businessDetailsErrors,
		isBusinessDetailsFormValid,
		clearExtraDetailsValues,
		dropdownsOptions,
		getDropdownsOptions,
		handleDateChange,
		formTemplates,
		fetchFormTemplatesByIdProfessionsArray,
		setFormTemplateAnswers,
		formTemplateAnswers,
		handleFormTemplateQuestionChange,
		findDependencyAnswer,
		findDependencyQuestion,
	};
}
