import { businessDetailsFormText, uploadFilesFormText } from '../../db/formHebrewText';
import '../../index.scss';
import '../../styles/Form.scss';
import './component.style.scss';
import RadioButton from '../RadioButton';
import InFormCheckBox from '../InFormCheckBox';
import { useStore } from '../../store';
import { useEffect, useState } from 'react';
import Input from '../Input/Input';
import { FrontendPhrase } from '../../ts/interfaces';

export default function ProfessionPhraseForm() {
	const { checkFormState } = useStore().activeStepContext;
	const currentFormsValues = localStorage.getItem('forms');
	const forms = currentFormsValues ? JSON.parse(currentFormsValues) : {};
	const currentBusinessDetails = forms.businessDetails || {};
	const { updateFormsContainer } = useStore().formsContainerContext;
	const {
		handleChange,
		professionPhraseFormValues,
		handleCheckboxChange,
		handleInputChange,
		isProfessionPhraseFormValid,
		checkAndSetIsFormValid,
		clearMifratValues,
		displayValues,
		mapValues,
		deduplicateArrayByKey,
	} = useStore().professionPhraseFormContainerContext;
	const { setBusinessDetailsValues, businessDetailsValues, businessDetailsErrors, placeKindData, handleChange: handleBusinessDetailsFormChange } = useStore().businessDetailsFormContext;
	const { chosenProfessions, phrases, isRequestCabahutPrivileged, surfaceInputToDisplay, defineNegishutRequirements, setAdvancedNegishutRequirements } = useStore().chosenProfessionsContext;
	const { updateMifratState } = useStore().formsContainerContext;
	const [cabahutProfessionPhrases, setCabahutProfessionPhrases] = useState<FrontendPhrase[]>([]);
	const [noneCabahutProfessionPhrases, setNoneCabahutProfessionPhrases] = useState<FrontendPhrase[]>([]);
	const [radioButtons, setRadioButtons] = useState<any>();
	const displaySurfaceInput = surfaceInputToDisplay === 'surface' || surfaceInputToDisplay === 'both';
	const displayAgra3Input = surfaceInputToDisplay === 'agra3' || surfaceInputToDisplay === 'both';
	console.log(surfaceInputToDisplay);

	const { validityChanged } = useStore().formsContainerContext;
	const showIdsendError = !professionPhraseFormValues.idsend && validityChanged;
	const showWhereIsPlacedError = !professionPhraseFormValues.where_is_placed && validityChanged;
	const showMultyBuildingFlageError = !professionPhraseFormValues.multy_building_flag && validityChanged;

	useEffect(() => {
		checkFormState(isProfessionPhraseFormValid, 4);
		updateFormsContainer('formSummary', professionPhraseFormValues);
	}, [professionPhraseFormValues]);

	useEffect(() => {
		const thereIsMifrat = chosenProfessions.some((profession: any) => profession.mifrat_checked_flag === 1 && (profession.idmaslulim_34_1 > 0 || profession.idmaslulim_34_2 > 0));
		updateMifratState(thereIsMifrat);
	}, [chosenProfessions]);

	useEffect(() => {
		if (!phrases.some((phrase: any) => phrase.licensing_factor_code === -1)) return;
		const anyNegishutProfessions = phrases.filter((phrase: any) => {
			return phrase.licensing_factor_code === -1;
		});
		if (!anyNegishutProfessions.length) setAdvancedNegishutRequirements(null);
		const negishutInputs = phrases.filter((phrase: any) => {
			return phrase.licensing_factor_code === -1 && phrase.component_type === 'number';
		});
		defineNegishutRequirements(negishutInputs);
	}, [professionPhraseFormValues.chosenPhrases]); // negishut logic useEffect

	useEffect(() => {
		const uniqueArrayByDossierColumnName = deduplicateArrayByKey(phrases, 'gov_il_column_name');
		const professionPhrases = mapValues(uniqueArrayByDossierColumnName);

		const noneCabahutProfessionPhrases = professionPhrases.filter((phrase: any) => phrase.licensing_factor_code !== 6);
		const cabahutProfessionPhrases = professionPhrases.filter((phrase: any) => phrase.licensing_factor_code === 6);
		const cabahutChildPhrases = cabahutProfessionPhrases.filter((phrase: any) => {
			return phrase.dossier_column_name_pointer?.length > 0;
		});

		if (cabahutChildPhrases.length) {
			const parentCabahutProfessionPhrases = cabahutProfessionPhrases.filter((phrase: any) => {
				return !phrase.dossier_column_name_pointer?.length;
			});
			const pointers = cabahutChildPhrases.flatMap((phrase: any) => phrase.dossier_column_name_pointer || []);

			const parentPhrases = pointers.flatMap((pointer: any) => professionPhrases.filter((phrase: any) => phrase.dossier_column_name === pointer));
			const uniqueParentPhrases = [...new Set(parentPhrases)];
			const phrasesToDisplay = getPhrasesToDisplay(uniqueParentPhrases, cabahutChildPhrases, professionPhraseFormValues);

			const allPhrases = Array.from(new Set([...parentCabahutProfessionPhrases, ...phrasesToDisplay]));

			setCabahutProfessionPhrases(allPhrases);
		} else setCabahutProfessionPhrases(cabahutProfessionPhrases);

		setNoneCabahutProfessionPhrases(noneCabahutProfessionPhrases);
		if (professionPhrases.length) checkAndSetIsFormValid();
		if (!radioButtons) {
			const dropdownOptions = placeKindData
				.filter((item: any) => item.list_kind === 2)
				.map((item: any) => {
					return { descrip: item.descrip?.trim(), value: item.code };
				});
			setRadioButtons(dropdownOptions);
		}
	}, [phrases, professionPhraseFormValues]); // cabahut logic useEffect

	const onInputChange = (phrase: FrontendPhrase, value: string, dossier_column_name: string | null) => {
		handleInputChange(phrase, phrase.id, value);
		if (!dossier_column_name) return;
		const updatedBusinessDetailsFormValues = { ...businessDetailsValues };
		updatedBusinessDetailsFormValues[dossier_column_name] = value;
		setBusinessDetailsValues(updatedBusinessDetailsFormValues);
		const updatedBusinessDetails = { ...currentBusinessDetails };
		updatedBusinessDetails[dossier_column_name] = value;
		updateFormsContainer('businessDetails', updatedBusinessDetails);
	};

	const renderPhraseInput = (phrase: any) => {
		const inputType = phrase.component_type?.trim() === 'number' ? 'decimal' : 'checkbox';
		const inputName = `phrase-${phrase.id}`;
		const isInChosenProfessions = chosenProfessions.some((profItem: { profession: string }) => profItem.profession?.trim() === phrase.profession?.trim());
		const inputValue = displayValues.find((item: any) => item.id === phrase.id)?.value || phrase.value;
		const radioButtonBehaviorIsNormal = phrase.radio_button_behavior === 'normal';
		if (phrase.component_type === 'radio_button') {
			const showPhraseError = validityChanged && professionPhraseFormValues.chosenPhrases[phrase.id]?.value === undefined;

			return (
				<div className="phrase-radio-buttons-container flex column" key={inputName}>
					<span tabIndex={0} className="phrase-title flex column" id={`${inputName}_title`} aria-label={`יש לבחור תשובה נכונה לשאלה: ${phrase.descrip}`}>
						{phrase.descrip}
						{!!showPhraseError && <span className="required">יש לבחור תשובה נכונה לשאלה</span>}
					</span>
					{radioButtonBehaviorIsNormal && (
						<div className="radio-button-wrapper">
							<RadioButton
								name={inputName}
								labelText="כן"
								value="1"
								isRequired={true}
								onChange={() => handleCheckboxChange(phrase, true, isInChosenProfessions)}
								isChecked={professionPhraseFormValues.chosenPhrases[phrase.id]?.value === 1}
							/>
							<RadioButton
								name={inputName}
								labelText="לא"
								value="0"
								isRequired={true}
								onChange={() => handleCheckboxChange(phrase, false, isInChosenProfessions)}
								isChecked={professionPhraseFormValues.chosenPhrases[phrase.id]?.value === 0}
							/>
							<span className="phrase-additional-descrip">{phrase.descrip_addition}</span>
						</div>
					)}
					{!radioButtonBehaviorIsNormal && (
						<div className="radio-button-wrapper">
							<RadioButton
								name={inputName}
								labelText="כן"
								value="0"
								isRequired={true}
								onChange={() => handleCheckboxChange(phrase, false, isInChosenProfessions)}
								isChecked={professionPhraseFormValues.chosenPhrases[phrase.id]?.value === 0}
							/>
							<RadioButton
								name={inputName}
								labelText="לא"
								value="1"
								isRequired={true}
								onChange={() => handleCheckboxChange(phrase, true, isInChosenProfessions)}
								isChecked={professionPhraseFormValues.chosenPhrases[phrase.id]?.value === 1}
							/>
							<span className="phrase-additional-descrip">{phrase.descrip_addition}</span>
						</div>
					)}
				</div>
			);
		}

		if (phrase.component_type === 'number') {
			return (
				<div aria-label={`${phrase.descrip}`} className="phrase-input-container flex column" key={inputName}>
					<Input
						legend={phrase.descrip}
						isRequired={true}
						onChange={(e: any) => onInputChange(phrase, e.target.value, phrase.dossier_column_name?.trim())}
						value={inputValue}
						errorMessage={businessDetailsErrors.surface}
						name={inputName}
						type={inputType}
						placeholder={''}
						min={0}
						max={0}
						pattern={undefined}
					/>
				</div>
			);
		}

		return null;
	};

	function getPhrasesToDisplay(uniqueParentPhrases: any[], cabahutChildPhrases: any[], professionPhraseFormValues: any): any[] {
		const phrasesToDisplay: any[] = [];

		uniqueParentPhrases.forEach((parentPhrase) => {
			const { dossier_column_name, id } = parentPhrase;
			const parentPhraseValue = professionPhraseFormValues.chosenPhrases[id]?.value;
			phrasesToDisplay.push(parentPhrase);
			const childPhrases = cabahutChildPhrases.filter((childPhrase) => childPhrase.dossier_column_name_pointer === dossier_column_name);
			childPhrases.forEach((childPhrase) => {
				const { question_should_be_displayed } = childPhrase;
				const shouldDisplayChild =
					(question_should_be_displayed === 'dossier_column_name_true' && parentPhraseValue === 1) ||
					(question_should_be_displayed.includes('dossier_column_name_fals') && parentPhraseValue === 0);
				if (shouldDisplayChild) {
					phrasesToDisplay.push(childPhrase);
				}
			});
		});

		return phrasesToDisplay;
	}
	return (
		<form className="form form_fourth">
			<div className="form-top-wrapper flex column" style={{ gap: 15 }}>
				<h1 className="form__title">תצהירים ושיוך למסלולים</h1>
				<h2 className="form__instructions flex column">
					<span>{businessDetailsFormText.requiredFieldsMessage}</span>
				</h2>
				<div className="form__fourth-step-block">
					{forms.isMifrat && (
						<>
							<h2 className="form__title" style={{ margin: 0 }}>
								{uploadFilesFormText.firstBlockTitle}
							</h2>
							<button type="button" className="reset-button " onClick={clearMifratValues}>
								נקה תשובות לשאלת התצהיר
							</button>
							<ol>
								<li>
									<div className="form__radio-buttons">
										<RadioButton
											extraClass={'input_radio-check'}
											isRequired={false}
											onChange={handleChange}
											labelText={uploadFilesFormText.firstBlockRadioButtonFirst}
											ariaLabel={`${uploadFilesFormText.firstBlockRadioButtonFirst}`}
											value={1}
											name="mezuraz_bniya_1_flag"
											isChecked={professionPhraseFormValues.mezuraz_bniya_1_flag === '1'}
										/>
										<RadioButton
											ariaLabel={`${uploadFilesFormText.firstBlockRadioButtonSecond}`}
											isRequired={false}
											extraClass={'input_radio-check'}
											onChange={handleChange}
											value={0}
											labelText={uploadFilesFormText.firstBlockRadioButtonSecond}
											isChecked={professionPhraseFormValues.mezuraz_bniya_1_flag === '0'}
											name="mezuraz_bniya_1_flag"
										/>
									</div>
								</li>
								<li>
									<div className="form__radio-buttons">
										<InFormCheckBox
											name="mezuraz_bniya_3_flag"
											onChange={handleChange}
											labelText={uploadFilesFormText.firstBlockCheckBox}
											isRequired={false}
											checkState={professionPhraseFormValues.mezuraz_bniya_3_flag === '1'}
										/>
									</div>
								</li>
							</ol>
						</>
					)}
				</div>
				<div className="form__fourth-step-block">
					{isRequestCabahutPrivileged && (
						<>
							<h2 className="form__title " style={{ margin: 0 }}>
								כבאות
							</h2>
							<div className="cabahut-container flex column " style={{}}>
								{displayAgra3Input ||
									(displaySurfaceInput && (
										<div className="surface-row ">
											{displayAgra3Input && (
												<Input
													legend={businessDetailsFormText.businessArea}
													onChange={handleBusinessDetailsFormChange}
													value={businessDetailsValues.agra3}
													name="agra3"
													type="decimal"
													isRequired={true}
													placeholder={''}
													errorMessage={businessDetailsErrors.agra3}
													min={0}
													max={0}
													pattern={undefined}
												/>
											)}
											{displaySurfaceInput && (
												<Input
													legend={businessDetailsFormText.totalArea}
													isRequired={true}
													onChange={handleBusinessDetailsFormChange}
													value={businessDetailsValues.surface}
													errorMessage={businessDetailsErrors.surface}
													name="surface"
													type="decimal"
													placeholder={''}
													min={0}
													max={0}
													pattern={undefined}
												/>
											)}
										</div>
									))}
								<div>
									<div className="phrase-input-container flex column">
										<div className="date-time-input-container">
											<div className="fieldset">
												<h2 tabIndex={0} id="idsend_title" className="list-question-title" aria-label={`יש לבחור תשובה נכונה לשאלה: היכן ממוקם העסק?`}>
													<span>
														<span className="required">*</span>
														<span>מיקום העסק במבנה :</span>
													</span>
													{!!showIdsendError && <span className="required">חובה לסמן את אחת האפשרויות </span>}
												</h2>
												<div className="form__radio-buttons">
													{radioButtons?.map((item: any, index: number) => (
														<RadioButton
															isRequired={true}
															onChange={handleChange}
															labelText={item.descrip}
															value={item.value}
															name="idsend"
															isChecked={professionPhraseFormValues.idsend === (index + 1).toString()}
														/>
													))}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<h2 tabIndex={0} className="list-question-title" id={'where_is_placed_title'} aria-label={`יש לבחור תשובה נכונה לשאלה: היכן ממוקם העסק?`}>
										<span>
											<span className="required">*</span>
											היכן ממוקם העסק?
										</span>
										{!!showWhereIsPlacedError && <span className="required">חובה לסמן את אחת האפשרויות </span>}
									</h2>

									<div className="form__radio-buttons">
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											labelText={'קניון'}
											value={0}
											name="where_is_placed"
											isChecked={professionPhraseFormValues.where_is_placed === '0'}
										/>
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											value={1}
											labelText={'מרכז מסחרי מבונה מעל 2000 מ”ר ללא חברת ניהול  '}
											isChecked={professionPhraseFormValues.where_is_placed === '1'}
											name="where_is_placed"
										/>
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											value={2}
											labelText={'מרכז מסחרי מבונה מעל 5000 מ”ר עם חברת ניהול  '}
											isChecked={professionPhraseFormValues.where_is_placed === '2'}
											name="where_is_placed"
										/>
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											value={3}
											labelText={'אף אחת מן האפשרויות'}
											isChecked={professionPhraseFormValues.where_is_placed === '3'}
											name="where_is_placed"
										/>
									</div>
								</div>
								<div>
									<h2
										tabIndex={0}
										id="multy_building_flag_title"
										className="list-question-title"
										aria-label={`יש לבחור תשובה נכונה לשאלה: האם העסק נמצא במבנה המשמש לכמה שימושים שונים ? (כגון מסחר ומשרדים, מסחר ומגורים וכדומה)}`}
									>
										<span>
											<span className="required">*</span>האם העסק נמצא במבנה המשמש לכמה שימושים שונים ? (כגון מסחר ומשרדים, מסחר ומגורים וכדו’)
										</span>
										{!!showMultyBuildingFlageError && <span className="required">חובה לסמן את אחת האפשרויות </span>}
									</h2>

									<div className="form__radio-buttons">
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											value={1}
											labelText={'כן'}
											isChecked={professionPhraseFormValues.multy_building_flag === '1'}
											name="multy_building_flag"
										/>
										<RadioButton
											isRequired={true}
											onChange={handleChange}
											value={0}
											labelText={'לא'}
											isChecked={professionPhraseFormValues.multy_building_flag === '0'}
											name="multy_building_flag"
										/>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
				{cabahutProfessionPhrases.length > 0 && (
					<div className="form__fourth-step-block">
						<div className="phrases-wrapper flex" style={{ width: '100%' }}>
							<div className="phrases-container">
								{cabahutProfessionPhrases.map((phrase) => (
									<div className="phrase-title-input-container" key={`phrase-${phrase.id}`}>
										{renderPhraseInput(phrase)}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{noneCabahutProfessionPhrases.length > 0 && (
					<div className="form__fourth-step-block">
						<h2 className="form__title " style={{ margin: 0 }}>
							שאלות נוספות :
						</h2>
						<div className="phrases-wrapper flex" style={{ width: '100%' }}>
							<div className="phrases-container">
								{noneCabahutProfessionPhrases.map((phrase) => (
									<div className="phrase-title-input-container" key={`phrase-${phrase.id}`}>
										{renderPhraseInput(phrase)}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		</form>
	);
}
