// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/delete.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__deleteProfession {
  all: unset;
  cursor: pointer;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/RemoveButton/component.style.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,eAAA;EACA,mDAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;EACA,2BAAA;EACA,sBAAA;EACA,iBAAA;AACD","sourcesContent":[".form__deleteProfession {\n\tall: unset;\n\tcursor: pointer;\n\tbackground: url(../../images/delete.svg);\n\twidth: 22px;\n\theight: 22px;\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n\tobject-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
