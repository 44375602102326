export const MunInfoConfig = () => {
	const fillObjectWithValues = <ObjectType extends { name: string }, RecordValueType>(
		objects: ObjectType[],
		dataFromDb: Record<string, RecordValueType>
	): (ObjectType & { text?: RecordValueType })[] => {
		return objects.map((obj) => {
			const key = obj.name;
			const value = dataFromDb[key];
			if (value !== undefined && value !== null && value !== '') {
				return { ...obj, text: value };
			}
			return obj;
		});
	};

	return {
		fillObjectWithValues,
	};
};
