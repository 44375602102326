import { useEffect, useRef, useState } from 'react';
import './component.style.scss';
import upload from '../../images/upload-file.svg';
import download from '../../images/download.svg';
import { CircularProgress } from '@mui/material';
import RemoveButton from '../RemoveButton/RemoveButton';
import BasicModal from '../Modal/Modal';
import imageCompression from 'browser-image-compression';

export const handleImageCompression = async (image: File) => {
	const options = {
		maxSizeMB: 0.5,
		useWebWorker: true,
		maxWidthOrHeight: 1280,
		initialQuality: 0.4,
		alwaysKeepResolution: false,
	};
	return await imageCompression(image, options);
};

export default function File({ fileToDownload, text, onChange, name, type, isUploaded, isLoading, isRequired, removeFile, fileChosen, originName, validityChanged, ariaLabel }: any) {
	const [isModalOpen, setModal] = useState(false);
	const [fileSizeError, setFileSizeError] = useState<string | null>(null);
	const inputRef = useRef<any>(null);
	const labelRef = useRef<any>(null); // Reference to the label element

	const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB size limit

	function openModal() {
		setModal(true);
	}

	function closeModal() {
		setModal(false);
	}

	const handleDownload = () => {
		fileToDownload.forEach((file: any) => {
			const fileSplit = file.split('.');
			const fileExt = fileSplit[fileSplit.length - 1];
			const fileName = fileSplit[0].split('/');
			const link = document.createElement('a');
			link.href = file;
			link.download = `${fileName[fileName.length - 1]}.${fileExt}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		});
	};

	function handleRemove(e: any) {
		e.target.parentNode.parentNode.parentNode.querySelector('.file_input').value = null;
		removeFile();
	}

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileOfOwner = event.target.ariaLabel === 'העלה תעודת זהות' || event.target.ariaLabel === 'העלה מיופה כוח';
		const file = event.target?.files?.[0];
		if (file) {
			let finalFile = file;
			if (fileOfOwner && file.size > FILE_SIZE_LIMIT) {
				try {
					finalFile = await handleImageCompression(file);
					if (finalFile.size > FILE_SIZE_LIMIT) {
						setFileSizeError('הקובץ גדול מדי, נא להעלות קובץ עד 5MB');
						inputRef.current.value = null;
						return;
					}
				} catch (error) {
					setFileSizeError('שגיאה בדחיסת הקובץ, נא לנסות שנית');
					inputRef.current.value = null;
					return;
				}
			}
			setFileSizeError(null);
			const newEvent = {
				...event,
				target: {
					...event.target,
					name,
					type: 'file',
					files: [finalFile],
				},
			};
			onChange(newEvent);
		}
	};

	const displayError = !!(validityChanged && isRequired && !isUploaded);

	useEffect(() => {
		if (displayError && inputRef.current) {
			inputRef.current.focus();
			labelRef.current.focus();
		}
	}, [displayError]);

	return (
		<div
			className="file-wrapper"
			aria-label={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
			title={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
			tabIndex={0}
			onKeyDown={(e: React.KeyboardEvent) => {
				if (e.key === 'Enter' && type === 'download') {
					handleDownload();
				} else if (e.key === 'Enter' && type !== 'download') {
					inputRef.current?.click();
				}
			}}
		>
			<span aria-label={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`} title={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}>
				<label
					ref={labelRef}
					title={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
					aria-label={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
					tabIndex={0}
					onKeyDown={(e: React.KeyboardEvent) => {
						if (e.key === 'Enter' && type === 'download') {
							handleDownload();
						} else if (e.key === 'Enter' && type !== 'download') {
							inputRef.current?.click();
						}
					}}
					className="file"
					onClick={() => {
						if (type === 'download') {
							handleDownload();
						}
					}}
				>
					{isLoading && <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />}
					{type !== 'download' && (
						<input
							title={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
							aria-label={ariaLabel ? ariaLabel : type === 'download' ? `הורדת ${name}` : `העלאת ${name}`}
							id={name}
							accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							className="file_input"
							style={{ visibility: 'hidden', width: 0, position: 'absolute' }}
							type="file"
							onChange={handleFileChange}
							name={name}
							required={isRequired}
							ref={inputRef}
						/>
					)}
					<BasicModal isOpen={isModalOpen} closeModal={closeModal} image={fileChosen} />
					<img className="file__icon" src={type === 'upload' ? upload : download} alt="" />
					<span className="file__text">{text}</span>
				</label>
			</span>
			{fileSizeError && <span className="file-error">{fileSizeError}</span>}
			{displayError && <span className="file-error"> {`חובה לצרף ${text}`}</span>}
			{isUploaded && (
				<div className="file__tooltip">
					<RemoveButton ariaLabel={`לחץ להסרת ${name}`} onClick={handleRemove} />
					<button
						tabIndex={0}
						onClick={openModal}
						onKeyDown={(e) => {
							if (e.key === 'Enter') openModal();
						}}
						className="origin-name"
						type="button"
					>
						{originName}
					</button>
				</div>
			)}
		</div>
	);
}
