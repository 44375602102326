import { createContext, useContext } from 'react';
import { useActiveStep } from './entities/useActiveStep';
import { useChosenProfessions } from './entities/useChosenProfessions';
import { useFormsContainer } from './entities/useFormsContainer';
import { useProfessionPhraseForm } from './entities/useProfessionPhraseForm';
import { useGovData } from './entities/useGovData';
import { useRequiredFilesForm } from './entities/useRequiredFilesForm';
import { useBusinessDetailsForm } from './entities/useBusinessDetailsForm';
import { useOwnersForm } from './entities/useOwnersForm';

const GlobalContext = createContext({});

export const StoreProvider = ({ children }: any) => {
	const govDataContext = useGovData();
	const activeStepContext = useActiveStep();
	const chosenProfessionsContext = useChosenProfessions();
	const formsContainerContext = useFormsContainer();
	const businessDetailsFormContext = useBusinessDetailsForm();
	const ownersFormContainerContext = useOwnersForm();
	const professionPhraseFormContainerContext = useProfessionPhraseForm();
	const requiredFilesFormContainerContext = useRequiredFilesForm();

	const store = {
		govDataContext,
		activeStepContext,
		chosenProfessionsContext,
		formsContainerContext,
		businessDetailsFormContext,
		ownersFormContainerContext,
		professionPhraseFormContainerContext,
		requiredFilesFormContainerContext,
	};
	return <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>;
};

export const useStore = () => {
	const store: any = useContext(GlobalContext);
	return store;
};
