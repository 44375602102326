import { v4 as uniqueId } from 'uuid';
import { InewOwner } from '../ts/interfaces';

export const newOwner: InewOwner = {
	isValid: false,
	ownerKind: '',
	ownerStatus: '',
	firstname: '',
	name: '',
	telephon1: '',
	telephon2: '',
	zipcode: undefined,
	post: undefined,
	adress1: '',
	adress2: '',
	adress3: '',
	osek: '',
	email: '',
	setAsDeliveryAddress: '0',
	id: uniqueId(),
	errors: {},
	files: {},
};
