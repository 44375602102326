// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: #507586;
  font-size: 16px !important;
  font-weight: 500;
  letter-spacing: 1px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}

.disabled-row {
  background-color: rgba(195, 195, 195, 0.2666666667);
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid/component.style.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,WAAA;AACD;;AAEA;EACC,cAAA;EACA,0BAAA;EACA,gBAAA;EACA,mBAAA;AACD;;AAEA;;EAEC,yBAAA;EACA,iBAAA;EACA,YAAA;AACD;;AAEA;EACC,mDAAA;AACD","sourcesContent":["table {\n\tborder-collapse: collapse;\n\twidth: 100%;\n}\n\nth {\n\tcolor: #507586;\n\tfont-size: 16px !important;\n\tfont-weight: 500;\n\tletter-spacing: 1px;\n}\n\ntd,\nth {\n\tborder: 1px solid #dddddd;\n\ttext-align: right;\n\tpadding: 8px;\n}\n\n.disabled-row {\n\tbackground-color: #c3c3c344;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
