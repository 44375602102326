import { useEffect } from 'react';
import { useStore } from '../store';
import OwnersForm from './OwnersForm/OwnersForm';

export default function OwnersFormContainer() {
	const { owners } = useStore().ownersFormContainerContext;
	const { updateFormsContainer, updateFormsValidation } = useStore().formsContainerContext;
	const { checkFormState } = useStore().activeStepContext;
	const thereIsInvalidOwner = owners.some((owner: any) => owner.isValid === false);
	const chosenProfessions = JSON.parse(localStorage.getItem('chosenProfessions') ?? '');
	const formsState = !thereIsInvalidOwner;

	useEffect(() => {
		// Create a new variable that removes the 'files' key from each owner object
		const ownersWithoutFiles = owners.map((owner: any) => {
			const { files, ...rest } = owner;
			return rest;
		});

		// Update the forms container with the modified ownersWithoutFiles
		updateFormsContainer('owners', ownersWithoutFiles);
		updateFormsContainer('chosenProfessions', chosenProfessions);
		updateFormsValidation('owners', formsState);
		checkFormState(formsState, 2);
	}, [owners]);

	return (
		<section className="step-two-container">
			{owners.map((owner: any, index: number) => {
				return <OwnersForm key={owner.id} id={owner.id} ownersArrayIndex={index} />;
			})}
		</section>
	);
}
