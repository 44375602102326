import { useEffect, useState } from 'react';
import '../../index.scss';
import Signature from '../Signature';
import './component.style.scss';
import { businessDetailsFormText, uploadFilesFormText } from '../../db/formHebrewText';
import { useStore } from '../../store';
import File from '../File/File';
import tazhirFileDocx from '../../assets/files/nagish.pdf';
import negishutPdf from '../../assets/files/tazthirNegishut.pdf';
import tazhirKabahut from '../../assets/files/tazthirCabahut.pdf';
import havatDaatPdf from '../../assets/files/havatDaat.pdf';
import InFormCheckBox from '../InFormCheckBox';
import Input from '../Input/Input';

export default function RequiredFilesForm() {
	const { owners } = useStore().ownersFormContainerContext;
	const [negishutText, setNegishutText] = useState('');
	const [negishutFiles, setNegishutFiles] = useState<any[]>([]);
	const { setRequiredFilesFormValues, removeFile, checkRequiredFilesFormValid, requiredFilesFormValues, handleChange, globalRequiredFiles } = useStore().requiredFilesFormContainerContext;
	const { businessDetailsValues, handleChange: handleChangeBusinessDetails, businessDetailsErrors } = useStore().businessDetailsFormContext;
	const { chosenProfessions, checkTazhirNegishut, advancedNegishutRequirements, defineCabahutTypeBySurfaceCondition } = useStore().chosenProfessionsContext;
	const lastProf = chosenProfessions[chosenProfessions.length - 1];
	const { validityChanged } = useStore().formsContainerContext;
	const { thereIsNegishut } = checkTazhirNegishut();
	const { updateFormsContainer } = useStore().formsContainerContext;
	const { professionPhraseFormValues } = useStore().professionPhraseFormContainerContext;

	useEffect(() => {
		updateFormsContainer('businessDetails', businessDetailsValues);
		const formSummaryFromDb = localStorage.getItem('forms');
		const parsedForms = formSummaryFromDb ? JSON.parse(formSummaryFromDb) : {};
		parsedForms.formSummary.files = requiredFilesFormValues.files;
		parsedForms.formSummary.signature = requiredFilesFormValues.signature;
		localStorage.setItem('forms', JSON.stringify(parsedForms));
		checkRequiredFilesFormValid();
	}, [requiredFilesFormValues, businessDetailsValues]);

	const showYepoyKoakh = owners.some((owner: any) => {
		return owner.ownerKind === '4';
	});

	useEffect(() => {
		const fetchCheckListData = async () => {
			let files = [...globalRequiredFiles];
			if (businessDetailsValues.type === '1') {
				files.push({
					descrip: 'תעודת ע.מ/רשם חברות',
					id: null,
					gov_api_code: null,
					idprofession: null,
					isRequired: true,
				});
				files.push({
					descrip: 'פרוטוקול מורשה חתימה',
					id: null,
					gov_api_code: null,
					idprofession: null,
					isRequired: true,
				});
				files.push({
					descrip: 'העלאת חותמת חברה',
					id: null,
					gov_api_code: null,
					idprofession: null,
					isRequired: true,
				});
			}
			if (thereIsNegishut) {
				files.push({
					descrip: `נגישות ${negishutText}`,
					id: null,
					gov_api_code: null,
					idprofession: null,
					fileToDownload: negishutFiles,
					hasDownloadLink: true,
					isRequired: false,
				});
			}

			if (professionPhraseFormValues?.thereIsProfessionPrivilegedToCabahutMaslul) {
				if (defineCabahutTypeBySurfaceCondition() === 'maslul') {
					files.push({
						descrip: 'תצהיר כבאות',
						id: null,
						gov_api_code: null,
						idprofession: null,
						fileToDownload: [tazhirKabahut],
						hasDownloadLink: true,
						isRequired: false,
					});
				}
			}
			if (showYepoyKoakh) {
				files.push({
					descrip: 'יפויי כוח',
					id: null,
					gov_api_code: null,
					idprofession: null,
					isRequired: true,
				});
			}
			files.sort((a: any, b: any) => {
				return b.isRequired - a.isRequired;
			});
			setRequiredFilesFormValues({ files, signature: '' });
		};
		fetchCheckListData();
	}, [chosenProfessions, professionPhraseFormValues, businessDetailsValues.type, thereIsNegishut, showYepoyKoakh, negishutText]);

	useEffect(() => {
		if (advancedNegishutRequirements !== null) {
			if (advancedNegishutRequirements) {
				setNegishutFiles([tazhirFileDocx]);
				setNegishutText("חוות דעת מורשה נגישות שירות + מתו''ס");
			} else {
				setNegishutFiles([tazhirFileDocx, negishutPdf]);
				setNegishutText('מסמך תצהיר ובדיקה עצמית');
			}
		} else {
			const surface = businessDetailsValues.surface;
			const isTazthirNegishut = chosenProfessions.some((prof: { nagish_surface_1: number }) => prof.nagish_surface_1 > surface);
			const isHavatDaat = chosenProfessions.some((prof: { nagish_surface_2: number; nagish_surface_1: number }) => prof.nagish_surface_2 > surface && prof.nagish_surface_1 < surface);
			if (isTazthirNegishut) {
				setNegishutFiles([tazhirFileDocx, negishutPdf]);
				setNegishutText('מסמך תצהיר ובדיקה עצמית');
			} else if (isHavatDaat) {
				setNegishutText('חוות דעת מורשה נגישות שירות');
				setNegishutFiles([havatDaatPdf]);
			} else {
				setNegishutFiles([tazhirFileDocx]);
				setNegishutText("חוות דעת מורשה נגישות שירות + מתו''ס");
			}
		}
	}, [businessDetailsValues.surface, chosenProfessions, advancedNegishutRequirements]); // negishut file logic useEffect

	return (
		<form className="form form_fifth">
			<div className="form__fifth-step-block">
				<h1 className="form__title"> {uploadFilesFormText.summaryTitle} </h1>
				<ul className="form__summary-ul">
					<li>
						<strong>
							{uploadFilesFormText.summaryRow1} :
							{chosenProfessions.map((prof: any) => {
								return (
									<span className="profession" key={prof.profession}>
										{prof.profession}
										{prof !== lastProf && ', '}
									</span>
								);
							})}
						</strong>
					</li>
					{uploadFilesFormText.summaryRows.map((item) => (
						<li key={item.text}>{item.text}</li>
					))}
				</ul>
				{requiredFilesFormValues.files.length > 0 && (
					<ol className="form__file-list">
						{requiredFilesFormValues.files.map((item: any, index: number) => (
							<li
								className="form__file-list-item"
								title={` ${item.fileToDownload ? ' הורדת המסמך' : 'העלאת המסמך'}`}
								aria-label={` ${item.fileToDownload ? ' הורדת המסמך' : 'העלאת המסמך'} ${item.descrip}`}
								key={item.descrip}
							>
								<div
									className="form__file-list-item_container"
									title={` ${item.fileToDownload ? ' הורדת המסמך' : 'העלאת המסמך'}`}
									aria-label={` ${item.fileToDownload ? ' הורדת המסמך' : 'העלאת המסמך'} ${item.descrip}`}
									key={item.descrip}
								>
									<p>
										{item.isRequired && <span style={{ color: '#B73D3D' }}>*</span>}
										{item.descrip}
									</p>
									<div className="flex column" style={{ gap: '2vh' }} aria-label={`הורדת המסמך ${item.descrip}`}>
										{item.hasDownloadLink && (
											<div className="form__file-list-item_files" aria-label={`הורדת המסמך ${item.descrip}`}>
												<File
													accept="image/*,application/pdf,.dwg,.rvt,.ifc,.dxf,"
													isLoading={false}
													fileToDownload={item.fileToDownload}
													type={'download'}
													name={item.descrip}
													isRequired={item?.isRequired}
													text={`הורדת המסמך ${item.descrip}`}
													onChange={handleChange}
													isUploaded={false}
												/>
											</div>
										)}

										<div
											className="form__file-list-item_files"
											aria-label={`${
												validityChanged && item.isRequired && !requiredFilesFormValues?.files[index]?.isUploaded ? `חובה להעלות את הקובץ: ${item.descrip}` : `${item.descrip}`
											}`}
											title={`${validityChanged && item.isRequired && !requiredFilesFormValues?.files[index]?.isUploaded ? `חובה להעלות את הקובץ: ${item.descrip}` : `${item.descrip}`}`}
										>
											<File
												accept="image/*,application/pdf,.dwg,.rvt,.ifc,.dxf,"
												isLoading={false}
												ariaLabel={`${
													validityChanged && item.isRequired && !requiredFilesFormValues?.files[index]?.isUploaded ? `חובה להעלות את הקובץ: ${item.descrip}` : `${item.descrip}`
												}`}
												originName={requiredFilesFormValues?.files[index]?.originName}
												fileChosen={requiredFilesFormValues?.files[index]?.file}
												type="upload"
												isRequired={item?.isRequired}
												name={item.descrip}
												text={`${validityChanged && item.isRequired && !requiredFilesFormValues?.files[index]?.isUploaded ? `חובה להעלות את הקובץ: ${item.descrip}` : `${item.descrip}`}`}
												onChange={handleChange}
												removeFile={() => removeFile(item.descrip)}
												isUploaded={requiredFilesFormValues?.files[index]?.isUploaded ? true : false}
												validityChanged={validityChanged}
											/>
										</div>
									</div>
								</div>
							</li>
						))}
					</ol>
				)}
			</div>
			<div className="form__checkboxes">
				<InFormCheckBox
					labelText={businessDetailsFormText.acceptEmailMessages}
					onChange={handleChangeBusinessDetails}
					checkState={businessDetailsValues.takana_39_email_flag === '1'}
					name="takana_39_email_flag"
				/>
				{businessDetailsValues.takana_39_email_flag === '1' && (
					<>
						<Input
							legend={businessDetailsFormText.email}
							isRequired={true}
							min={0}
							max={50}
							pattern={undefined}
							onChange={handleChangeBusinessDetails}
							value={businessDetailsValues.email}
							errorMessage={businessDetailsErrors.email}
							name="email"
							type="email"
							placeholder={''}
						/>
						<br />
					</>
				)}
				<InFormCheckBox
					labelText={businessDetailsFormText.acceptSmsMessages}
					checkState={businessDetailsValues.takana_39_sms_flag === '1'}
					onChange={handleChangeBusinessDetails}
					name="takana_39_sms_flag"
				/>
				{businessDetailsValues.takana_39_sms_flag === '1' && (
					<Input
						legend={businessDetailsFormText.phone}
						isRequired={true}
						min={10}
						max={10}
						pattern="[0-9]*"
						onChange={handleChangeBusinessDetails}
						value={businessDetailsValues.telephon1}
						errorMessage={businessDetailsErrors.telephon1}
						name="telephon1"
						type="text"
						placeholder={''}
					/>
				)}
			</div>
			<div className="form__auth">
				<Signature />
			</div>
		</form>
	);
}
