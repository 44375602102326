// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-container-wrapper {
  position: relative;
  display: inline-block;
}

.contact-us-container {
  background-color: #007aa5;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  position: relative;
  color: white;
  font-size: 25px;
  border: 2px solid transparent;
  transition: all 0.3s;
}
.contact-us-container:hover {
  color: #007aa5;
  border-color: #007aa5;
  background-color: white;
}

.contact-us-info-wrapper {
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 20px;
  width: 300px;
  z-index: 101;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 500px) {
  .contact-us-info-wrapper {
    width: 55vw;
  }
}

.contact-us-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUsInfo/style.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,qBAAA;AACD;;AAEA;EACC,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,6BAAA;EACA,oBAAA;AACD;AACC;EACC,cAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAGA;EACC,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,uCAAA;AAAD;AAEC;EATD;IAUE,WAAA;EACA;AACF;;AAEA;EACC,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;AACD","sourcesContent":[".contact-us-container-wrapper {\n\tposition: relative;\n\tdisplay: inline-block;\n}\n\n.contact-us-container {\n\tbackground-color: #007aa5;\n\tborder-radius: 50%;\n\theight: 55px;\n\twidth: 55px;\n\tposition: relative;\n\tcolor: white;\n\tfont-size: 25px;\n\tborder: 2px solid transparent;\n\ttransition: all 0.3s;\n\n\t&:hover {\n\t\tcolor: #007aa5;\n\t\tborder-color: #007aa5;\n\t\tbackground-color: white;\n\t}\n}\n\n.contact-us-info-wrapper {\n\tbackground-color: #f2f2f2;\n\tborder-radius: 15px;\n\tpadding: 20px;\n\twidth: 300px;\n\tz-index: 101;\n\tposition: relative;\n\tbox-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n\n\t@media (max-width: 500px) {\n\t\twidth: 55vw;\n\t}\n}\n\n.contact-us-overlay {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tbackground-color: rgba(0, 0, 0, 0.5);\n\tz-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
