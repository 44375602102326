import '../index.scss';
import muni_logo from '../images/muni-logo.svg';
import citysys_logo from '../images/logo-nomral.svg';
import { useEffect, useState } from 'react';

interface headerProps {
	mun_logo: string;
	mun_name?: string;
}
export default function Header({ mun_logo, mun_name }: headerProps) {
	const [eprRoute, setEprRoute] = useState<boolean>(false);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const eprRouteValue = urlParams.get('eprRoute');
		eprRouteValue ? setEprRoute(!!eprRouteValue) : setEprRoute(false);
	}, []);

	return (
		<header className="header">
			<div>
				<img
					aria-label={eprRoute ? 'מוני הבית הדיגיטלי' : 'סיטי מערכות רישוי'}
					src={eprRoute ? muni_logo : citysys_logo}
					className="app__logo"
					alt={eprRoute ? 'מוני הבית הדיגיטלי' : 'סיטי מערכות רישוי'}
				/>
			</div>
			<div className="flex column">
				<span className="header-text">בקשה לרישיון עסק</span>
				<span className="header-text">{mun_name}</span>
			</div>
			<div className="header-logo-wrapper">
				<img src={mun_logo} aria-label={`לוגו הרשות ${mun_name}`} className="mun__logo" alt={`לוגו הרשות ${mun_name}`} />
			</div>
		</header>
	);
}
