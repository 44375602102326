import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MenuItem, Select, TextField } from '@mui/material';
import '../styles/Form.scss';
import '../index.scss';
import '../styles/MUI.scss';
import Input from './Input/Input';
import RadioButton from './RadioButton';
import { useStore } from '../store';
import { businessDetailsFormText } from '../db/formHebrewText';
import { getFormDataInLocalStorage, setFormBooleanValue, setFormDataInLocalStorage } from '../utils/localStorage';
import { DateTimeState } from '../ts/interfaces';
import MuiAutocomplete from './Select/Select';
import 'dayjs/locale/he';
dayjs.locale('he');

export default function ExtraBusinessDetails() {
	const currentFormsValues = localStorage.getItem('forms');
	const forms = currentFormsValues ? JSON.parse(currentFormsValues) : {};
	const { checkFormState } = useStore().activeStepContext;
	const {
		formTemplates,
		businessDetailsRef,
		businessDetailsValues,
		businessDetailsErrors,
		handleChange,
		placeKindData,
		handleDateChange,
		isBusinessDetailsFormValid,
		formTemplateAnswers,
		handleFormTemplateQuestionChange,
		clearExtraDetailsValues,
		findDependencyAnswer,
		findDependencyQuestion,
	} = useStore().businessDetailsFormContext;
	const { updateFormsContainer, updateFormsValidation } = useStore().formsContainerContext;
	const extraDetailsBoolFromLocalStorage = getFormDataInLocalStorage('extraDetails');
	const [eventTypeDropdownOptions, setEventTypeDropdownOptions] = useState<any>(null);
	const [dateTimeState, setDateTimeState] = useState<DateTimeState>({
		startDate: {
			value: forms.businessDetails.startDate ?? null,
			isValid: false,
			error: '',
		},
		date_send_first: {
			value: forms.businessDetails.date_send_first ?? null,
			isValid: false,
			error: '',
		},
		endDate: {
			value: forms.businessDetails.endDate ?? null,
			isValid: false,
			error: '',
		},
		date_nitra_first: {
			value: forms.businessDetails.date_nitra_first ?? null,
			isValid: false,
			error: '',
		},
	});

	const validateOneTimeEventInputs = (): boolean => {
		const { startDate, date_send_first, endDate, date_nitra_first } = dateTimeState;
		const isValidArray = [startDate.error.trim().length === 0, date_send_first.error.trim().length === 0, endDate.error.trim().length === 0, date_nitra_first.error.trim().length === 0];
		return !isValidArray.some((error) => !error) && !!businessDetailsValues.walking_order;
	};

	useEffect(() => {
		checkFormState(false, 1);
		setFormDataInLocalStorage(formTemplateAnswers, 'formTemplateAnswers');
	}, [extraDetailsBoolFromLocalStorage, formTemplateAnswers]);

	useEffect(() => {
		updateFormsContainer('businessDetails', businessDetailsValues);
		updateFormsValidation('businessDetails', isBusinessDetailsFormValid);
		checkFormState(isBusinessDetailsFormValid, 1);
		if (businessDetailsValues.agra2_flag === '1') setFormBooleanValue('extraDetailsFormValid', isBusinessDetailsFormValid && validateOneTimeEventInputs());
		if (!eventTypeDropdownOptions && placeKindData) {
			const dropdownOptions = placeKindData
				.filter((item: any) => item.list_kind === 1)
				.map((item: any) => {
					return { descrip: item.descrip?.trim(), value: item.code };
				});
			setEventTypeDropdownOptions(dropdownOptions);
		}
	}, [businessDetailsValues, dateTimeState]);

	const handleStartDateChange = (date: Dayjs | null) => {
		const isStartDateValid = isValidDate(date);
		const errorMessage = getErrorMessage(date);

		setDateTimeState((prev) => ({
			...prev,
			startDate: {
				value: date,
				isValid: isStartDateValid,
				error: errorMessage,
			},
			date_send_first: {
				value: null,
				isValid: false,
				error: '',
			},
			endDate: {
				value: null,
				isValid: false,
				error: '',
			},
			date_nitra_first: {
				value: null,
				isValid: false,
				error: '',
			},
		}));

		handleDateChange('startDate', date);
		updateFormValue('startDate', date);
	};

	const handleEndDateChange = (date: Dayjs | null) => {
		setDateTimeState((prev: any) => {
			const isEndDateValid = isValidDate(date); // Check if the end date is within the allowed range
			let endDateErrorMessage = '';

			// Validate end date against start date and max allowed date
			if (isEndDateValid) {
				if (prev.startDate.value !== null && date) {
					// Check if the end date is before the start date
					if (date.isBefore(prev.startDate.value, 'day')) {
						endDateErrorMessage = 'תאריך סיום האירוע מוקדם מתאריך התחלת האירוע.';
					} else if (date.isAfter(maxAllowedDate, 'day')) {
						// Check if the end date exceeds the max allowed date
						endDateErrorMessage = 'תאריך חייב להיות עד 365 ימים מהיום.';
					} else {
						// Valid end date
						endDateErrorMessage = '';
					}
				}
			} else {
				// If the date is invalid, use the default error message
				endDateErrorMessage = getErrorMessage(date);
			}

			return {
				...prev,
				endDate: {
					value: date,
					isValid: isEndDateValid,
					error: endDateErrorMessage,
				},
			};
		});

		handleDateChange('endDate', date);
		updateFormValue('endDate', date);
	};

	const handleStartTimeChange = (time: Dayjs | null) => {
		if (time === null)
			setDateTimeState((prev) => {
				return {
					...prev,
					date_nitra_first: {
						value: null,
						isValid: false,
						error: '',
					},
				};
			});
		if (!time || !time.isValid()) return;

		let updatedTime: any;
		setDateTimeState((prev) => {
			const startDate = prev.startDate.value;
			updatedTime = time && startDate ? dayjs(startDate).hour(time.hour()).minute(time.minute()) : null;
			const isStartTimeValid = isValidTime(updatedTime);

			return {
				...prev,
				date_send_first: {
					value: updatedTime,
					isValid: isStartTimeValid,
					error: isStartTimeValid ? '' : 'יש להזין שעה תקינה.',
				},
				date_nitra_first: {
					value: null,
					isValid: false,
					error: '',
				},
			};
		});
		handleDateChange('date_send_first', updatedTime);
		updateFormValue('date_send_first', updatedTime);
	};

	const handleEndTimeChange = (time: Dayjs | null) => {
		if (!time || !time.isValid()) return;
		let updatedTime: any;
		setDateTimeState((prev) => {
			const endDate = prev.endDate.value;
			updatedTime = time && endDate ? dayjs(endDate).hour(time.hour()).minute(time.minute()) : null;
			const isEndTimeValid = isValidTime(updatedTime);
			const error = isEndTimeValid && prev.date_send_first.value && prev.date_send_first.value.isAfter(updatedTime) ? 'שעת הסיום מוקדמת משעת ההתחלה.' : isEndTimeValid ? '' : 'יש להזין שעה תקינה.';
			return {
				...prev,
				date_nitra_first: {
					value: updatedTime,
					isValid: isEndTimeValid,
					error: error,
				},
			};
		});
		handleDateChange('date_nitra_first', updatedTime);
		updateFormValue('date_nitra_first', updatedTime);
	};

	const getErrorMessage = (date: Dayjs | null): string => {
		if (!date) return 'יש להזין תאריך תקין .';
		if (date.isBefore(todayDate, 'day')) {
			return 'אין להזין תאריך שחלף.';
		} else if (date.isAfter(maxAllowedDate, 'day')) {
			return 'תאריך חייב להיות עד 365 ימים מהיום.';
		}

		return '';
	};

	const isValidDate = (date: any): boolean => {
		return date && dayjs(date).isValid();
	};

	const isValidTime = (time: any): boolean => {
		return time && dayjs(time).isValid();
	};

	const updateFormValue = (key: string, value: Dayjs | null) => {
		const currentFormsValues = localStorage.getItem('forms');
		const forms = currentFormsValues ? JSON.parse(currentFormsValues) : {};

		const currentBusinessDetails = forms.businessDetails || {
			startDate: { value: null, isValid: false, error: '' },
			date_send_first: { value: null, isValid: false, error: '' },
			endDate: { value: null, isValid: false, error: '' },
			date_nitra_first: { value: null, isValid: false, error: '' },
		};

		const updatedFormValues = {
			...currentBusinessDetails,
			[key]: value,
		};
		updateFormsContainer('businessDetails', updatedFormValues);
	};

	const renderQuestion = (formId: string, question: any, sectionId: number) => {
		const commonProps = {
			formId,
			questionId: question.id,
			sectionId,
			questionType: question.type,
		};

		if (question.logic) {
			const dependencyAnswer = findDependencyAnswer(formId, question.logic.dependencyQuestion, formTemplateAnswers);
			if (!dependencyAnswer) return null;

			const dependencyQuestion = findDependencyQuestion(question.logic.dependencyQuestion, formTemplates);
			if (!dependencyQuestion) return null;

			const selectedOption = dependencyQuestion.options.find((option: any) => option.id === dependencyAnswer.answer_option_id);
			const selectedLabel = selectedOption?.label || '';

			if (question.logic.condition === 'equals' && selectedLabel !== question.logic.answer) {
				return null;
			}
		}

		const currentForm = formTemplateAnswers?.find((form: { formId: string }) => form.formId === formId);
		const currentAnswer = currentForm?.answers?.find((ans: any) => ans.questionId === question.id);

		switch (question.type) {
			case 'short-text':
			case 'long-text':
			case 'number':
			case 'decimal':
				return (
					<Input
						legend={question.title}
						placeholder={question.placeholder ?? ''}
						isRequired={question.isRequired}
						value={currentAnswer?.answer_text ?? ''}
						onChange={(e: any) =>
							handleFormTemplateQuestionChange({
								...commonProps,
								value: e.target.value,
							})
						}
						type={question.type}
						name={''}
						errorMessage={''}
						min={question.minLength}
						max={question.maxLength}
						pattern={undefined}
					/>
				);

			case 'dropdown':
				return (
					<>
						<MuiAutocomplete
							id={`dropdown-${question.id}`}
							placeholder={question.placeholder}
							formNumber={+formId}
							options={question.options.map((option: any) => option.label)}
							name={question.title}
							errorMessage={question.isRequired ? 'זהו שדה חובה' : ''}
							value={question.options.find((option: any) => option.id === currentAnswer?.answer_option_id)?.label || ''}
							handleChange={(event: any, newInputValue: any) => {
								const selectedOption = question.options.find((option: any) => option.label === newInputValue);
								handleFormTemplateQuestionChange({
									...commonProps,
									value: selectedOption?.id || '',
								});
							}}
							handleClickProp={(newValue: any, name: any) => {
								const selectedOption = question.options.find((option: any) => option.label === newValue);
								handleFormTemplateQuestionChange({
									...commonProps,
									value: selectedOption?.id || '',
								});
							}}
							legend={question.title}
							isRequired={question.isRequired}
						/>
						<span className="question-instructions">{question.instructions}</span>
					</>
				);

			case 'checkboxes':
				const currentCheckboxAnswers = currentForm?.answers.filter((ans: any) => ans.questionId === question.id).map((ans: any) => ans.answer_option_id) || [];

				return (
					<>
						<legend>{question.title}</legend>
						<ul className="checkbox-list">
							{question.options.map((option: any) => {
								const isChecked = currentCheckboxAnswers.includes(option.id.toString());
								return (
									<li key={option.id} style={{ marginBottom: '8px' }}>
										<input
											type="checkbox"
											id={`checkbox-${option.id}`}
											checked={isChecked}
											onChange={(event) =>
												handleFormTemplateQuestionChange({
													...commonProps,
													value: option.id.toString(),
													checked: event.target.checked,
												})
											}
										/>
										<label htmlFor={`checkbox-${option.id}`} style={{ marginRight: '10px' }}>
											{option.label}
										</label>
									</li>
								);
							})}
						</ul>
						<span className="question-instructions">{question.instructions}</span>
					</>
				);

			case 'date':
				return (
					<>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div className="fieldset">
								<span className="date-time-input-legend">
									{question.isRequired && <span className="required">*</span>}
									<span>{question.title}</span>
								</span>
								<DatePicker
									disableOpenPicker={true}
									className={`picker`}
									value={currentAnswer?.answer_text ? dayjs(currentAnswer.answer_text, 'YYYY-MM-DD') : null}
									onChange={(date) =>
										handleFormTemplateQuestionChange({
											...commonProps,
											value: date ? date.format('YYYY-MM-DD') : null,
										})
									}
									renderInput={(params) => {
										const newParams = {
											...params,
											inputProps: {
												...params.inputProps,
												placeholder: '----/--/--',
												type: 'tel',
											},
										};
										return <TextField style={{ width: '100%' }} {...newParams} placeholder="dd/mm/yyyy" />;
									}}
								/>
							</div>
						</LocalizationProvider>
						<span className="question-instructions">{question.instructions}</span>
					</>
				);

			case 'time':
				return (
					<>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div className="fieldset">
								<span className="date-time-input-legend">
									{question.isRequired && <span className="required">*</span>}
									<span>{question.title}</span>
								</span>
								<TimePicker
									minutesStep={1}
									className={`picker`}
									value={currentAnswer?.answer_text ? dayjs(currentAnswer.answer_text, 'HH:mm') : null}
									onChange={(time) =>
										handleFormTemplateQuestionChange({
											...commonProps,
											value: time ? time.format('HH:mm') : null,
										})
									}
									renderInput={(params: any) => {
										const newParams = {
											...params,
											inputProps: {
												...params.inputProps,
												placeholder: '--:--',
												type: 'tel',
											},
										};
										return <TextField style={{ width: '100%' }} {...newParams} />;
									}}
									disableOpenPicker={true}
									ampm={false}
								/>
							</div>
						</LocalizationProvider>
						<span className="question-instructions">{question.instructions}</span>
					</>
				);
			default:
				return null;
		}
	};

	const todayDate = dayjs();
	const maxAllowedDate = dayjs().add(364, 'day');

	return (
		<form className="form" ref={businessDetailsRef} style={{ height: '100%' }}>
			<div className="form-top-wrapper flex column" style={{ gap: 15 }}>
				<h1 className="form__title">פרטים נוספים על העסק</h1>
				<div className="business-details-form__top__no-height flex">
					<button type="button" className="reset-button underline" onClick={() => clearExtraDetailsValues()}>
						אפס טופס
					</button>
					<div className="form__instructions flex column">
						<span>{businessDetailsFormText.requiredFieldsMessage}</span>
					</div>
				</div>
			</div>
			<div className="extra-business-inputs-wrapper">
				<div className="remarks flex column full-width" style={{ gap: '4px' }}>
					<span className="remark-group ">
						<span className="remark-title">* שטח מבונה -</span>
						<span> במידה ויש מספר מבנים נפרדים, יש להזין את שטח המבנה הגדול ביותר</span>
					</span>
					<span className="remark-group ">
						<span className="remark-title">* שטח מבונה -</span>
						<span> לרבות סגירת חורף</span>
					</span>
					<span className="remark-group ">
						<span className="remark-title">** שטח כולל -</span>
						<span> הקרקע שבה מנוהל העסק עד לגבולותיו של העסק, לרבות קירותיו החיצוניים והגדרות התוחמים אותו</span>
					</span>
				</div>
				<div></div>
				<div className="flex column">
					<h2 className="form__title_small" style={{ marginBottom: '15px' }}>
						שטח :
					</h2>
					<div className="extra-business-right-container">
						<Input
							legend={businessDetailsFormText.totalArea}
							isRequired={true}
							onChange={handleChange}
							value={businessDetailsValues.surface}
							errorMessage={businessDetailsErrors.surface}
							name="surface"
							type="decimal"
							placeholder={''}
							min={0}
							max={6}
							pattern={undefined}
						/>
						<Input
							legend={businessDetailsFormText.businessArea}
							onChange={handleChange}
							value={businessDetailsValues.agra3}
							name="agra3"
							type="decimal"
							isRequired={true}
							placeholder={''}
							maxNumber={businessDetailsValues.surface}
							errorMessage={businessDetailsErrors.agra3}
							min={0}
							max={6}
							pattern={undefined}
						/>
						<Input
							legend={'גובה תקרה'}
							isRequired={false}
							onChange={handleChange}
							value={businessDetailsValues.ceilling_height}
							errorMessage={businessDetailsErrors.ceilling_height}
							name="ceilling_height"
							type="decimal"
							placeholder={''}
							min={0}
							max={6}
							pattern="[0-9,.]*"
						/>
						<Input
							legend={'מספר קומות'}
							onChange={handleChange}
							value={businessDetailsValues.number_of_floors}
							name="number_of_floors"
							type="text"
							placeholder={''}
							errorMessage={businessDetailsValues.number_of_floors}
							min={0}
							max={3}
							pattern={undefined}
						/>

						<Input
							legend={'גובה אחסנה'}
							onChange={handleChange}
							value={businessDetailsValues.storage_height}
							name="storage_height"
							type="decimal"
							placeholder={''}
							errorMessage={businessDetailsValues.storage_height}
							min={0}
							max={5}
							pattern={undefined}
						/>
						<Input
							legend={'מספר חדרים'}
							name="rooms"
							onChange={handleChange}
							value={businessDetailsValues.rooms}
							type="decimal"
							placeholder={''}
							errorMessage={businessDetailsValues.rooms}
							min={0}
							max={5}
							pattern={undefined}
						/>
					</div>
				</div>
				<div className="flex column">
					<h2 className="form__title_small" style={{ marginBottom: '15px' }}>
						פרטים נוספים :
					</h2>
					<div className="extra-business-left-container">
						<Input
							legend={businessDetailsFormText.numberOfEmployees}
							onChange={handleChange}
							value={businessDetailsValues.workers}
							name="workers"
							type="number"
							isRequired={false}
							placeholder={''}
							errorMessage={''}
							min={0}
							max={5}
							pattern={undefined}
						/>
						<Input
							legend={businessDetailsFormText.numberOfSeats}
							onChange={handleChange}
							value={businessDetailsValues.seats}
							errorMessage={businessDetailsErrors.seats}
							name="seats"
							type="number"
							placeholder={''}
							min={0}
							max={5}
							pattern={undefined}
						/>
						<Input
							legend={'מספר מיטות'}
							onChange={handleChange}
							value={businessDetailsValues.number_of_beds}
							errorMessage={businessDetailsErrors.number_of_beds}
							name="number_of_beds"
							type="number"
							placeholder={''}
							min={0}
							max={5}
							pattern={undefined}
						/>
						<Input
							legend={'קיבולת'}
							onChange={handleChange}
							value={businessDetailsValues.kibolet}
							name="kibolet"
							type="number"
							placeholder={''}
							errorMessage={businessDetailsValues.kibolet}
							min={0}
							max={5}
							pattern={undefined}
						/>
					</div>
				</div>
			</div>
			<div className="one-time-event-section flex column">
				<div className="flex column" style={{ gap: '2vh' }}>
					<h2 className="form__title_small" tabIndex={0} style={{ paddingTop: 0 }}>
						האם הרישיון מיועד לאירוע חד פעמי:
					</h2>
					<div className="radio-buttons-wrapper flex " style={{ gap: '5vw' }}>
						<RadioButton isRequired={true} onChange={handleChange} name="agra2_flag" isChecked={businessDetailsValues.agra2_flag === '0'} labelText={'לא'} value={'0'} />
						<RadioButton isRequired={true} onChange={handleChange} name="agra2_flag" isChecked={businessDetailsValues.agra2_flag === '1'} labelText={'כן'} value={'1'} />
					</div>
				</div>
			</div>
			{businessDetailsValues.agra2_flag === '1' && (
				<div className="one-time-event-extra-questions">
					<div className="flex column" style={{ gap: '5vh' }}>
						<h2 className="form__title_small" style={{ paddingTop: 0 }}>
							תאריך התחלה ותאריך סיום של האירוע:
						</h2>
						<div className="pickers-wrapper">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<div className="date-time-rows-wrapper">
									<div className="date-time-row">
										<div className="date-time-input-container">
											<div className="fieldset">
												<span className="date-time-input-legend">
													<span className="required">*</span>
													<span>תאריך התחלה :</span>
												</span>
												<DatePicker
													disableOpenPicker={true}
													value={dateTimeState.startDate.value}
													className={`picker ${dateTimeState.startDate.error ? 'error' : ''}`}
													onChange={handleStartDateChange}
													minDate={todayDate}
													maxDate={maxAllowedDate}
													renderInput={(params: any) => {
														const newParams = {
															...params,
															inputProps: {
																...params.inputProps,
																placeholder: '----/--/--',
																type: 'tel',
															},
														};
														return <TextField {...newParams} placeholder="dd/mm/yyyy" />;
													}}
												/>
											</div>
											{dateTimeState.startDate.error && <span className="date-row-error">{dateTimeState.startDate.error}</span>}
										</div>
										<div className="date-time-input-container">
											<div className="fieldset">
												<span className="date-time-input-legend">
													<span className="required">*</span>
													<span>שעת התחלה :</span>
												</span>
												<TimePicker
													minutesStep={1}
													ampm={false}
													disabled={!dateTimeState.startDate.isValid}
													disableOpenPicker={true}
													className={`picker ${dateTimeState.date_send_first.error ? 'error' : ''}`}
													value={dateTimeState.date_send_first.value}
													onChange={handleStartTimeChange}
													renderInput={(params: any) => {
														const newParams = {
															...params,
															inputProps: {
																...params.inputProps,
																placeholder: '--:--',
																type: 'tel',
															},
														};
														return <TextField {...newParams} />;
													}}
												/>
											</div>
											{dateTimeState.date_send_first.error && <span className="date-row-error">{dateTimeState.date_send_first.error}</span>}
										</div>
									</div>
									<div className="date-time-row">
										<div className="date-time-input-container">
											<div className="fieldset">
												<span className="date-time-input-legend">
													<span className="required">*</span>
													<span>תאריך סיום :</span>
												</span>
												<DatePicker
													disableOpenPicker={true}
													className={`picker ${dateTimeState.endDate.error ? 'error' : ''}`}
													value={dateTimeState.endDate.value}
													onChange={handleEndDateChange}
													minDate={todayDate}
													maxDate={maxAllowedDate}
													renderInput={(params: any) => {
														const newParams = {
															...params,
															inputProps: {
																...params.inputProps,
																placeholder: '----/--/--',
																type: 'tel',
															},
														};
														return <TextField {...newParams} placeholder="dd/mm/yyyy" />;
													}}
													disabled={!dateTimeState.startDate.isValid}
												/>
												{dateTimeState.endDate.error && <span className="date-row-error">{dateTimeState.endDate.error}</span>}
											</div>
										</div>
										<div className="date-time-input-container">
											<div className="fieldset">
												<span className="date-time-input-legend">
													<span className="required">*</span>
													<span>שעת סיום :</span>
												</span>
												<TimePicker
													minutesStep={1}
													className={`picker ${dateTimeState.date_nitra_first.error ? 'error' : ''}`}
													value={dateTimeState.date_nitra_first.value}
													ampm={false}
													disableOpenPicker={true}
													onChange={handleEndTimeChange}
													renderInput={(params: any) => {
														const newParams = {
															...params,
															inputProps: {
																...params.inputProps,
																placeholder: '--:--',
																type: 'tel',
															},
														};
														return <TextField {...newParams} disabled={!dateTimeState.date_send_first.isValid} />;
													}}
													disabled={!dateTimeState.endDate.isValid}
												/>
											</div>

											{dateTimeState.date_nitra_first.error && <span className="date-row-error">{dateTimeState.date_nitra_first.error}</span>}
										</div>
									</div>
								</div>
							</LocalizationProvider>
							<div className="date-time-input-container">
								<div className="fieldset">
									<span className="select-input-legend">
										<span className="required">*</span>
										<span>סוג האירוע :</span>
									</span>
									<Select value={businessDetailsValues.walking_order !== undefined ? businessDetailsValues.walking_order : ''} name="walking_order" onChange={handleChange} displayEmpty>
										{eventTypeDropdownOptions?.map((item: any) => (
											<MenuItem key={item.value} value={item.value}>
												{item.descrip}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{formTemplates
				.filter((formTemplate: any, index: number, self: any[]) => index === self.findIndex((t) => t.id === formTemplate.id))
				.map((formTemplate: any, formIndex: number) => (
					<div key={formTemplate.id} className="form-template">
						<div>{formTemplate.formTitle}</div>
						{formTemplate.sections.map((section: any, sectionIndex: number) => (
							<div key={sectionIndex} className="form-section">
								<h2 className="section-title">{section.title}</h2>
								<div className="section-questions-grid">
									{section.questions.map((question: any) => {
										const renderedQuestion = renderQuestion(formTemplate.id, question, section.id);
										// Check if the question type is either 'date' or 'time'
										const style = question.type === 'date' || question.type === 'time' ? { paddingTop: '15px' } : {};
										return renderedQuestion ? (
											<div key={question.id} className="question-item" style={style}>
												{renderedQuestion}
											</div>
										) : null;
									})}
								</div>
							</div>
						))}
					</div>
				))}
		</form>
	);
}
