import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { StoreProvider } from './store';

// @ts-expect-error TS(2584): Cannot find name 'document'. Do you need to change... Remove this comment to see the full error message
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<StoreProvider>
			<App />
		</StoreProvider>
	</StrictMode>
);
