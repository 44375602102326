import { govLinks, streets } from './../db/govLinks';
import { Api } from './Api';

class GovService extends Api {
	// No authorization needed
	getVillages() {
		return this.axiosRequest(govLinks.villagesArray, { headers: undefined }, false);
	}

	// No authorization needed
	getGlobalStreetsByChosenVillage(ident: string) {
		return this.axiosRequest(streets(ident), { headers: undefined }, false);
	}

	// Authorization required
	getStreetByCurrentVillage(code: string) {
		return this.axiosRequest(streets(code), { headers: undefined }, false); // With authorization
	}
}

export const govService = new GovService();
