// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mun-info-rows-wrapper .mun-info-row {
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.mun-info-rows-wrapper .mun-info-row .value {
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-size: 12px;
}
.mun-info-rows-wrapper .mun-info-row svg {
  font-size: 12px;
}
.mun-info-rows-wrapper .reception-info-container {
  font-size: 12px;
}
.mun-info-rows-wrapper .reception-info-container .reception-info-title {
  font-size: 12px;
  color: #00739c;
  font-weight: 600;
  padding: 16px 0 8px 0;
}
.mun-info-rows-wrapper .reception-info-container .reception-info {
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/PreviewMunInfo/style.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AAAF;AAEE;EACC,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AAAH;AAGE;EACC,eAAA;AADH;AAKC;EACC,eAAA;AAHF;AAKE;EACC,eAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;AAHH;AAME;EACC,iBAAA;AAJH","sourcesContent":[".mun-info-rows-wrapper {\n\t.mun-info-row {\n\t\tline-height: 24px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tgap: 8px;\n\n\t\t.value {\n\t\t\twidth: 100%;\n\t\t\ttext-align: left;\n\t\t\tfont-weight: 600;\n\t\t\tfont-size: 12px;\n\t\t}\n\n\t\tsvg {\n\t\t\tfont-size: 12px;\n\t\t}\n\t}\n\n\t.reception-info-container {\n\t\tfont-size: 12px;\n\n\t\t.reception-info-title {\n\t\t\tfont-size: 12px;\n\t\t\tcolor: #00739c;\n\t\t\tfont-weight: 600;\n\t\t\tpadding: 16px 0 8px 0;\n\t\t}\n\n\t\t.reception-info {\n\t\t\tline-height: 24px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
