export function setFormDataInLocalStorage(data: unknown, localKey: string) {
	const stringData = JSON.stringify(data);
	localStorage.setItem(localKey, stringData);
}

export function getFormDataInLocalStorage(key: string, mock?: unknown) {
	const data = localStorage.getItem(key);
	if (data !== null) {
		return JSON.parse(data);
	} else {
		return mock;
	}
}

export function setFormBooleanValue(key: string, state: boolean) {
	localStorage.setItem(key, JSON.stringify(state));
}

export function getFormBooleanValue(key: string) {
	const res = localStorage.getItem(key);
	if (res !== null) {
		return JSON.parse(res);
	}
	return false;
}
