import { useEffect } from 'react';
import './accessibilty.scss';
const params = new URLSearchParams(window.location.search);
const munParamValue = params.get('mun');

const Accessibility = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleBackClick = () => {
		window.location.assign(`https://status-display-dev.citysys.co.il/${munParamValue}/navigation-after-login`);
		// TODO: env condition for production
	};
	return (
		<div className="accessibility-container">
			<h1>הצהרת נגישות למערכת רישוי מקוון מבית סיטי מערכות רישוי</h1>
			<p>
				בשנים האחרונות המערכות הדיגיטליות הפכו להיות הפלטפורמה העיקרית לפרסום המידע ושירותים השונים המוצעים על ידי החברה לציבור הרחב. מערכת רישוי מקוון מבית סיטי מערכות רישוי מאפשרת לבעל עסק להגיש
				בצורה מקוונת בקשה לרישיון עסק שתועבר ישירות לטיפול הרשות.
			</p>
			<p>במערכת זו, ניתן לבצע את הנושאים הבאים:</p>
			<ul>
				<li>הגשת בקשה לרישיון עסק לרבות צירוף המסמכים הרלוונטיים לבקשה.</li>
				<li>צפייה בנתוני בקשה קיימת.</li>
				<li>הקמת פניה למטפל בתיק לרבות העלאת קבצים.</li>
				<li>קבלת אינדיקציה והשלמת מסמכים חסרים.</li>
			</ul>
			<p>מטרת החברה בהנגשת המערכת היא יצירת שוויון הזדמנויות במרחב האינטרנטי לאנשים עם לקויות מגוונות ואנשים הנעזרים בטכנולוגיות מסייעות שונות בעת גלישה ברשת האינטרנט.</p>
			<h2>התאמות הנגישות במערכת המקוונת</h2>
			<p>
				במערכת זו בוצעו התאמות נגישות בהתאם להמלצות התקן הישראלי (ת"י 5568) ולנגישות תכנים באינטרנט ברמת AA בשילוב המלצות מסמך WCAG2.0 שפורסם באמצעות הארגון הבינלאומי W3C העוסק בתקינה ברשת
				האינטרנט.
			</p>
			<p>
				ההתאמות שבוצעו במערכת נבדקו באמצעות הדפדפנים הנפוצים ביותר: גוגל כרום ואינטרנט אקספלורר (EDGE). לשם קבלת חווית גלישה נעימה ומיטבית עם תוכנה להקראת מסך אנו ממליצים להשתמש בתוכנת NVDA בגרסה
				העדכנית ביותר.
			</p>
			<p>לצורך הנגשת המערכת לאנשים עם מוגבלות בוצעו מספר רב של פעולות, ביניהן:</p>
			<ul>
				<li>התאמת המערכת לניווט קל ונוח באמצעות המקלדת.</li>
				<li>שימוש מסגרת ברורה ובולטת בעת קבלת פוקוס כאשר המשתמש מנווט במערכת באמצעות המקלדת.</li>
				<li>שימוש בניגודיות חזותית מתאימה בין צבע הטקסט לצבע הרקע.</li>
				<li>תיוג הכותרות בצורה היררכית נכונה בהתאם למידע המפורסם בהן ולמבנה העמוד.</li>
				<li>שימוש בחתימה דיגיטלית נגישה המאפשרת חתימה מלאה על הבקשה בעת שימוש וניווט באמצעות המקלדת בלבד.</li>
			</ul>
			<p>התאמות שבוצעו במערכת המקוונת לגולשים המשתמשים בתוכנה להקראת מסך:</p>
			<ul>
				<li>שימוש בכותרות עמוד מתאימות וברורות בהתאם למידע המופיע בעמוד.</li>
				<li>שימוש בלייבלים מתאימים וברורים בשדות הקיימים במערכת.</li>
				<li>שימוש בשפה פשוטה ובהירה בכל עמודי המערכת.</li>
				<li>שימוש בפקודת aria-label להתאמת המידע המופיע במערכת לתוכנה להקראת מסך.</li>
			</ul>
			<p>
				הייעוץ והליווי המקיף בנושא נגישות מערכת הרישוי המקוון מבית סיטי מערכות רישוי בוצע על ידי צוות{' '}
				<a href="https://www.web-a.co.il/" target="_blank">
					WEB-A הנגשת אתרים, אפליקציות ומערכות מתקדמות.
				</a>
			</p>
			<p>במשרדי החברה לא מתקיימת קבלת קהל. למען הסר ספק, המידע המופיע בהצהרת נגישות זו מתייחס אך ורק לשימוש ותפעול מערכת הרישוי המקוונת מבית סיטי מערכות.</p>
			<p>במידה והנכם מעוניינים לקבל מידע נוסף אודות הרשות או מידע אודות התאמות הנגישות הפיזיים שבוצעו למשרדיה, יש לעבור להצהרת הנגישות המופיעה באתר האינטרנט של הרשות.</p>
			<p>צוות החברה מאמין כי לכל אדם עם מוגבלות צריכה להיות היכולת להשתמש במערכת האינטרנט בצורה שווה, מהנה וחווייתית.</p>
			<p>
				במערכת זה בוצעו שינויים והתאמות מיוחדות באמצעות הטכנולוגיה העדכנית והמתאימה ביותר לצרכי לקוחותינו והגולשים במערכת. עם זאת חשוב לציין כי ייתכן וימצאו אלמנטים מסוימים שאינם מונגשים בצורה
				מלאה או נמצאים בתהליכי הנגשה לאנשים עם מוגבלות.
			</p>
			<p>נתקלתם ברכיב שאינו נגיש במערכת? פנו אלינו ואנו מבטיחים לבדוק לשפר אותו בהקדם האפשרי וכמובן להעניק לכם את השירות הטוב ביותר בצורה מהירה, אישית ובהתאם לשביעות רצונכם.</p>
			<div className="contact-info">
				<h2>
					<strong>פרטי רכזת נגישות</strong>
				</h2>
				<p>החברה מינתה את הילה בן אליעזר לרכזת הנגישות של החברה.</p>
				<p>לבירורים נוספים, שאלות או בקשות מיוחדות בנושא נגישות המערכת ניתן ליצור עימנו קשר באמצעים הבאים:</p>
				<p>
					<strong>שם מלא:</strong> הילה בן אליעזר
				</p>
				<p>
					<strong>טלפון נייד:</strong> 054-5967167 (ניתן גם לפנות באמצעות הודעת SMS / וואטסאפ)
				</p>
				<p>
					<strong>דואר אלקטרוני:</strong> hila@citysys.co.il
				</p>
			</div>
			<p>הצהרת הנגישות עודכנה בתאריך 04/06/2024</p>
			<p>כל הזכויות בהצהרת נגישות זו שמורות לחברת WEB-A נגישות דיגיטלית בע"מ ואין להפיץ, להעתיק או לשכפל אותה.</p>
			<button className="back-button" onClick={handleBackClick}>
				חזור לעמוד הראשי
			</button>
		</div>
	);
};

export default Accessibility;
