import { useEffect, useState } from 'react';
import BusinessDetailsForm from '../../components/BusinessDetailsForm/BusinessDetailsForm';
import FinalToolTip from '../../components/FinalToolTip/FinalToolTip';
import ExtraBusinessDetails from '../../components/ExtraBusinessDetails';
import OwnersFormContainer from '../../components/OwnersContainer';
import ProfessionPhraseForm from '../../components/ProfessionPhraseForm/ProfessionPhraseForm';
import RequiredFilesForm from '../../components/RequiredFilesForm/RequiredFilesForm';
import ProfessionsForm from '../../components/ProfessionsForm/ProfessionsForm';
import { getFormDataInLocalStorage } from '../../utils/localStorage';

export const useActiveStep = () => {
	const [isNextArrowValid, setIsNextArrowValid] = useState(true);
	const [currentStep, setCurrentStep] = useState(0);
	const [componentToShow, setComponentToShow] = useState(<BusinessDetailsForm />);
	const businessDetailsFromLocalStorage = getFormDataInLocalStorage('businessDetails');
	const extraDetailsBoolFromLocalStorage = getFormDataInLocalStorage('extraDetailsFormValid');

	const updateStep = (action: 'next' | 'prev' | 'skip-forth-step' | 'prev-skip-forth-step') => {
		setCurrentStep((prev) => {
			switch (action) {
				case 'skip-forth-step':
					return prev + 2;
				case 'prev-skip-forth-step':
					return prev - 2;
				case 'next':
					return prev + 1;
				case 'prev':
					return prev - 1;
				default:
					return prev;
			}
		});
	};

	const checkRequiredBusinessDetailsFormValuesFromLocalStorage = () => {
		const businessDetailsValues = JSON.parse(localStorage.getItem('businessDetails') ?? 'null');
		if (!businessDetailsValues) return false;

		const requiredFormValues = [businessDetailsValues.address1, businessDetailsValues.address2, businessDetailsValues.profname, businessDetailsValues.address3, businessDetailsValues.osek];
		for (const value of requiredFormValues) {
			if (typeof value !== 'string' || value?.trim().length === 0) {
				return false;
			}
		}
		return true;
	};

	const checkFormState = (formState: boolean, step: number) => {
		if (step === 3) {
			setIsNextArrowValid(extraDetailsBoolFromLocalStorage);
			return;
		}
		if (currentStep === 0) {
			setIsNextArrowValid(checkRequiredBusinessDetailsFormValuesFromLocalStorage);
			return;
		}
		if (currentStep === step && formState) {
			setIsNextArrowValid(true);
		} else if (currentStep === step && !formState) {
			setIsNextArrowValid(false);
		}
	};

	const goToToolTipPage = (state: undefined | boolean) => {
		if (state !== undefined) {
			setComponentToShow(<FinalToolTip />);
			updateStep('next');
		}
	};

	useEffect(() => {
		switch (currentStep) {
			case 0:
				setComponentToShow(<ProfessionsForm />);
				break;
			case 1:
				setComponentToShow(<OwnersFormContainer />);
				break;
			case 2:
				setComponentToShow(<BusinessDetailsForm />);
				break;
			case 3:
				setComponentToShow(<ExtraBusinessDetails />);
				break;
			case 4:
				setComponentToShow(<ProfessionPhraseForm />);
				break;
			case 5:
				setComponentToShow(<RequiredFilesForm />);
				break;
			default:
		}
		if ((currentStep === 1 && businessDetailsFromLocalStorage?.agra3?.trim().length === 0) || businessDetailsFromLocalStorage?.surface?.trim().length === 0) {
			setIsNextArrowValid(false);
		}
	}, [currentStep]);

	return {
		setCurrentStep,
		currentStep,
		goToToolTipPage,
		updateStep,
		componentToShow,
		isNextArrowValid,
		checkFormState,
	};
};
