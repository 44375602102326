import { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import { Event } from '../../ts/types';
import { newOwner } from '../../mocks/ownersFormMock';
import { getFormDataInLocalStorage, setFormDataInLocalStorage } from '../../utils/localStorage';
import { handleImageCompression } from '../../components/File/File';

export const useOwnersForm = () => {
	const [owners, setOwners] = useState([{ ...newOwner }]);

	const clearOwnerValues = (id: string) => () => {
		const newOwners = owners.map((owner) => (owner.id === id ? { ...newOwner, id: owner.id } : owner));
		setOwners(newOwners);
		localStorage.removeItem('ownersForm');
	};

	useEffect(() => {
		const res = getFormDataInLocalStorage('ownersForm', [{ ...newOwner }]);
		setOwners(res);
	}, []);

	const handleChange = (id: string) => (event: Event) => {
		const target = event.target as HTMLInputElement;
		const name = target.name;
		const errorMessage = target.validationMessage;
		let value = target.value;

		if (target.type === 'checkbox') {
			value = target.checked ? '1' : '0';
		}

		const currentForm = target.closest('.form') as HTMLFormElement;

		setOwners((prevOwners) => {
			const updatedOwners = prevOwners.map((owner) =>
				owner.id === id
					? {
							...owner,
							isValid: currentForm.checkValidity(),
							[name]: value,
							errors: {
								...(name !== 'ident' && { [name]: errorMessage }),
							},
						}
					: owner
			);

			// Remove 'files' key from each owner object
			const ownersWithoutFiles = updatedOwners.map(({ files, ...rest }) => rest);

			// Update local storage with the filtered data
			setFormDataInLocalStorage(ownersWithoutFiles, 'ownersForm');

			return updatedOwners;
		});
	};

	const handleOwnerKindChange = (id: string) => (event: Event) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;

		setOwners((prevOwners) => {
			const updatedOwners = prevOwners.map((owner) =>
				owner.id === id
					? {
							...owner,
							ownerKind: value,
							ownerStatus: value === '1' ? '3' : owner.ownerStatus,
						}
					: owner
			);

			// Remove 'files' key from each owner object
			const ownersWithoutFiles = updatedOwners.map(({ files, ...rest }) => rest);

			// Update local storage with the filtered data
			setFormDataInLocalStorage(ownersWithoutFiles, 'ownersForm');

			return updatedOwners;
		});
	};

	const appendOwner = () => {
		setOwners((current) => {
			return [...current, { ...newOwner, id: uniqueId() }];
		});
	};

	const removeOwner = (id: any) => {
		const newOwnerArr = owners.filter((owner) => owner.id !== id);
		setOwners(newOwnerArr);
	};

	const getDeliveryAddress = () => {
		return owners.find((owner) => owner.setAsDeliveryAddress === '1');
	};

	const setOwnerFile = async (id: string, file: File, fileType: string) => {
		try {
			const compressedFile = file.type.startsWith('image') ? file : await handleImageCompression(file);
			const reader = new FileReader();
			reader.readAsDataURL(compressedFile);
			reader.onloadend = () => {
				const { result } = reader;
				setOwners((prevOwners) =>
					prevOwners.map((owner) =>
						owner.id === id
							? {
									...owner,
									files: {
										...owner.files,
										[fileType]: {
											name: file.name,
											file: result as string,
										},
									},
								}
							: owner
					)
				);
			};
		} catch (error) {
			console.error('Error compressing file:', error);
		}
	};

	// New Function: Remove File
	const removeOwnerFile = (id: string, fileType: string) => {
		setOwners((prevOwners) =>
			prevOwners.map((owner) =>
				owner.id === id
					? {
							...owner,
							files: {
								...owner.files,
								[fileType]: undefined, // or null, depending on how you want to handle it
							},
						}
					: owner
			)
		);
	};

	return {
		owners,
		setOwners,
		clearOwnerValues,
		appendOwner,
		removeOwner,
		handleChange,
		getDeliveryAddress,
		setOwnerFile, // Exporting the new file handling function with Base64 conversion
		removeOwnerFile, // Exporting the file removal function
		handleOwnerKindChange,
	};
};
