import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box } from '@mui/material';
import '../index.scss';
import { useStore } from '../store';

const Signature = () => {
	const { setSignatureValue, canvasRef, handleChange } = useStore().requiredFilesFormContainerContext;
	const [isSignature, setSignature] = useState(false);
	const [dataUrl, setDataUrl] = useState('');
	const [accessibleSignature, setAccessibleSignature] = useState('');
	const [isAccessibleMode, setIsAccessibleMode] = useState(false);
	const [accessibleSignatureImage, setAccessibleSignatureImage] = useState<string | null>('');
	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (isAccessibleMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isAccessibleMode]);
	useEffect(() => {
		setSignatureValue(dataUrl);
	}, [dataUrl]);

	const handleBegin = () => {
		setSignature(true);
	};

	const handleEnd = (e: Event) => {
		const currentValue = canvasRef.current;
		if (currentValue) {
			handleChange(e);
			setDataUrl(currentValue.getCanvas().toDataURL('image/png'));
		}
	};

	const handleSignatureClean = () => {
		const currentValue = canvasRef.current;
		if (currentValue) currentValue.clear();
		setDataUrl('');
		setSignature(false);
		setAccessibleSignature('');
		setAccessibleSignatureImage(null);
	};

	const handleAccessibleSignature = () => {
		setIsAccessibleMode(!isAccessibleMode);
		setAccessibleSignatureImage(null);
	};

	const handleAccessibleSignatureSubmit = () => {
		const signatureText = `${accessibleSignature}`;
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		if (ctx) {
			ctx.font = '30px Arial';
			ctx.fillText(signatureText, 10, 50);
			const dataUrl = canvas.toDataURL('image/png');
			setDataUrl(dataUrl);
			setAccessibleSignatureImage(dataUrl);
		}
		setIsAccessibleMode(false);
	};

	const handleRemoveAccessibleSignature = () => {
		setAccessibleSignatureImage(null);
		setAccessibleSignature('');
		setDataUrl('');
		setIsAccessibleMode(false);
	};

	return (
		<>
			<Box
				sx={{
					border: '1px solid #D6E4ED',
					display: 'flex',
					flexDirection: 'column !important',
					alignItems: 'center',
					position: 'relative !important',
				}}
			>
				{!isAccessibleMode && !accessibleSignatureImage && (
					<button type="button" aria-label="לחץ להסרת חתימה " onClick={handleSignatureClean} className="signature__clear">
						נקה חתימה
					</button>
				)}
				{!isAccessibleMode && !accessibleSignatureImage ? (
					<>
						<SignatureCanvas
							ref={canvasRef}
							penColor="gray"
							dotSize={3}
							velocityFilterWeight={0.05}
							canvasProps={{ width: 296.53, height: 140.77, className: 'sigCanvas' }}
							onBegin={handleBegin}
							onEnd={handleEnd}
						/>
						{!isSignature && <Box></Box>}
					</>
				) : accessibleSignatureImage ? (
					<>
						<img src={accessibleSignatureImage} alt="Accessible Signature" />
						<button className="signature-button" type="button" aria-label="לחץ להסרת חתימה נגישה" onClick={handleRemoveAccessibleSignature}>
							הסר חתימה
						</button>
					</>
				) : (
					<div className="flex" style={{ gap: 15 }}>
						<input
							ref={inputRef}
							className="signature-input"
							aria-label="הכנס שם מלא + תעודת זהות"
							type="text"
							value={accessibleSignature}
							onChange={(e) => setAccessibleSignature(e.target.value)}
							placeholder="הכנס שם מלא + תעודת זהות"
						/>
						<button className="signature-button" type="button" aria-label="	הטמעת חתימה נגישה בטופס הבקשה" onClick={handleAccessibleSignatureSubmit} disabled={!accessibleSignature}>
							הטמעת חתימה נגישה בטופס הבקשה
						</button>
					</div>
				)}
				{!isAccessibleMode && !accessibleSignatureImage && (
					<p className="signature__text" aria-label="החתימה שלך כאן">
						החתימה שלך כאן
					</p>
				)}
			</Box>

			{!accessibleSignatureImage && (
				<button className="signature-button" onClick={handleAccessibleSignature} type="button" aria-label={`${isAccessibleMode ? 'חזור לחתימה רגילה' : 'אני מעוניין בחתימה נגישה'}`}>
					{isAccessibleMode ? 'חזור לחתימה רגילה' : 'אני מעוניין בחתימה נגישה'}
				</button>
			)}
		</>
	);
};

export default Signature;
