import { useEffect } from 'react';
import mainService from '../../api/MainService';
import { useStore } from '../../store';
import './component.style.scss';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import { getFormDataInLocalStorage } from '../../utils/localStorage';

export default function NavArrows() {
	const { professionPhraseFormValues } = useStore().professionPhraseFormContainerContext;
	const { owners } = useStore().ownersFormContainerContext;
	const { setForms, dossierCreated, dossierCreatedSuccessfully, dossierDidNotCreatedSuccessfully, setLoading, setNumero, setPdf, isLoading, setDisplayPlanningAndConstructionLaw } =
		useStore().formsContainerContext;
	const { updateStep, goToToolTipPage, currentStep } = useStore().activeStepContext;
	const { getCabahutPrivilege, thereIsOneProfessionWithoutPrivilege, checkTazhirNegishut, chosenProfessions, getIdMaslulim34Value } = useStore().chosenProfessionsContext;
	const { setValidityChanged, validityChanged } = useStore().formsContainerContext;
	const { thereIsNegishut } = checkTazhirNegishut();

	async function sendForm() {
		const form = document.querySelector('form');
		if (form) {
			const formElements = form.elements;
			let firstInvalidElement: any;

			Array.from(formElements).forEach((element) => {
				if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
					if (!element.checkValidity()) {
						if (!firstInvalidElement) {
							firstInvalidElement = element;
						}
					}
				}
			});

			if (firstInvalidElement) {
				firstInvalidElement.focus();
				const labelElement: any = document.querySelector(`label[aria-label="העלאת ${firstInvalidElement.id}"]`);
				if (labelElement) {
					labelElement.focus();
				}
				setValidityChanged(validityChanged + 1);
				return;
			}
		}

		try {
			setLoading(true);
			// Fetch and parse data from localStorage, with error handling for corrupted JSON
			let forms: any = {};
			let formTemplateAnswers = {};
			try {
				const formFromLocalStorage = localStorage.getItem('forms');
				forms = formFromLocalStorage ? JSON.parse(formFromLocalStorage) : {};
				const formTemplateAnswersFromLocalStorage = localStorage.getItem('formTemplateAnswers');
				formTemplateAnswers = formTemplateAnswersFromLocalStorage ? JSON.parse(formTemplateAnswersFromLocalStorage) : {};
			} catch (parseError) {
				console.error('Failed to parse data from localStorage:', parseError);
			}

			// Transform chosen phrases if they exist
			const transformedChosenPhrases = transformChosenPhrases(forms.formSummary?.chosenPhrases || {});
			const idmaslulim_34 = getIdMaslulim34Value();
			const isRequestCabahutPrivileged = getCabahutPrivilege();

			setForms((prev: any) => ({
				...prev,
				platfromFlag: 'openRequest',
			}));
			const cabahutPtorValue = String(professionPhraseFormValues?.thereIsProfessionPrivilegedToCabahutPtor && isRequestCabahutPrivileged ? '1' : '0');
			const cabahutMaslulValue = String(professionPhraseFormValues?.thereIsProfessionPrivilegedToCabahutMaslul && isRequestCabahutPrivileged && cabahutPtorValue !== '1' ? '1' : '0');
			// Prepare data for submission
			forms.businessDetails = {
				...forms.businessDetails,
				ceilling_height: forms.businessDetails?.ceilling_height ? Number(forms.businessDetails.ceilling_height) : null,
				nagish: thereIsNegishut ? 1 : 0,
				idmaslulim_34,
				idstreet: parseInt(forms.formSummary?.where_is_placed || '0', 10),
				idsend: parseInt(forms.formSummary?.idsend || '0', 10) ?? null,
			};
			forms.formTemplateAnswers = formTemplateAnswers;
			forms.formSummary = {
				...forms.formSummary,
				chosenPhrases: transformedChosenPhrases,
				cabahut_ptor: cabahutPtorValue,
				cabahut_maslul: cabahutMaslulValue,
			};

			const modifiedForms = modifyForms(forms);
			const ownersFromLocal = JSON.parse(localStorage.getItem('ownersForm') ?? '[]');

			// Merge files property from `owners` into `modifiedForms.owners`
			modifiedForms.owners = ownersFromLocal.map((ownerFromLocal: { id: any; files: any; ownerKind?: string; ownerStatus?: string }) => {
				// Find the matching owner from the owners array
				const matchingOwner = owners.find((owner: { id: any }) => owner.id === ownerFromLocal.id);

				// Transform ownerKind and ownerStatus to kind and status
				const { ownerKind, ownerStatus, ...rest } = ownerFromLocal;
				const transformedOwner = {
					...rest,
					kind: ownerKind, // Rename ownerKind to kind
					status: ownerStatus, // Rename ownerStatus to status
					files: matchingOwner?.files || ownerFromLocal.files, // Merge files if available
				};

				return transformedOwner;
			});

			// Send data to the service
			const res = await mainService.createDossier(modifiedForms);

			if (res.dossierId) {
				setNumero(res.numero);
				setPdf(res.formSummaryPdf);
				dossierCreatedSuccessfully();
				localStorage.clear();
			} else {
				dossierDidNotCreatedSuccessfully();
			}
		} catch (error) {
			console.error('Error in sending form:', error);
			dossierDidNotCreatedSuccessfully();
		} finally {
			setLoading(false);
		}
	}

	function removeEmptyKeys(obj: any) {
		const newObj = { ...obj };
		for (const key in newObj) {
			if (newObj[key] === '' || newObj[key] === null) {
				delete newObj[key];
			} else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
				newObj[key] = removeEmptyKeys(newObj[key]);
			}
		}
		return newObj;
	}

	const transformChosenPhrases = (chosenPhrasesObj: any) => {
		return Object.keys(chosenPhrasesObj).map((key) => ({
			id: key,
			...chosenPhrasesObj[key],
		}));
	};

	function modifyForms(forms: any) {
		const modifiedForms = { ...forms };
		for (const key in modifiedForms) {
			if (key === 'businessDetails') {
				modifiedForms[key] = substringAddresses(modifiedForms[key]);
				modifiedForms[key] = removeEmptyKeys(modifiedForms[key]);
			} else if (key === 'owners') {
				modifiedForms[key] = modifiedForms[key].map((owner: any) => {
					const modifiedOwner = substringAddresses(owner);
					return removeEmptyKeys(modifiedOwner);
				});
			}
		}
		return modifiedForms;
	}

	function substringAddresses(data: any) {
		if (data.address1) {
			data.address1 = data.address1.substring(0, 24);
		}
		if (data.adress1) {
			data.adress1 = data.adress1.substring(0, 24);
		}
		return data;
	}

	useEffect(() => {
		goToToolTipPage(dossierCreated);
	}, [dossierCreated]);

	const handleStepClick = (direction: string) => {
		const formSummaryFromLocalStorage = localStorage.getItem('forms');
		const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
		if (direction === 'next' || direction === 'skip-forth-step') {
			const formElements = document.querySelectorAll('form');

			let isAllFormsValid = true;
			let firstInvalidElement: any;

			formElements.forEach((form) => {
				const formElements = form.elements;

				Array.from(formElements).forEach((element) => {
					if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
						if (!element.checkValidity()) {
							isAllFormsValid = false;
							if (!firstInvalidElement) {
								firstInvalidElement = element;
							}
							if (currentStep === 1 && element.type === 'file') {
								const ownerIndex = form.getAttribute('name');
								const fileName = element.id;
								const parentElement: any = element.closest('.file-wrapper');

								if (parentElement) {
									parentElement.style.position = 'relative';
									parentElement.focus();
								}

								if (ownerIndex !== null) {
									const numericOwnerIndex = Number(ownerIndex);
									if (!isNaN(numericOwnerIndex) && numericOwnerIndex >= 0 && numericOwnerIndex < owners.length) {
										const owner = owners[numericOwnerIndex];
										if (owner && owner.files && owner.files[fileName] && owner.files[fileName].file) {
											isAllFormsValid = true;
											firstInvalidElement = null;
										}
									}
								}
							}
						}
					}
				});
			});

			if (currentStep === 3 && forms.businessDetails.agra2_flag === '1') {
				const extraDetailsBoolFromLocalStorage = getFormDataInLocalStorage('extraDetailsFormValid');
				if (!extraDetailsBoolFromLocalStorage) return;
			}
			if (currentStep === 2 && forms.businessDetails.mezuraz_close_flag === '0') {
				setDisplayPlanningAndConstructionLaw(false);
			}

			if (firstInvalidElement) {
				if (currentStep === 4 || currentStep === 1) {
					const labelElement = document.getElementById(`${firstInvalidElement.name}_title`);
					if (labelElement) {
						labelElement.focus();
						if (document.activeElement === labelElement) {
							setValidityChanged(validityChanged + 1);
							return;
						}
					}
				}

				firstInvalidElement.focus();
				setValidityChanged(validityChanged + 1);
				return;
			}

			if (!isAllFormsValid) return;
		}

		let stepAction = direction;
		if (direction === 'next' && currentStep === 3) {
			if (thereIsOneProfessionWithoutPrivilege) {
				stepAction = forms.displayPlanningAndConstructionLaw ? 'next' : 'skip-forth-step';
			}
		} else if (direction === 'prev' && currentStep === 5) {
			if (!thereIsOneProfessionWithoutPrivilege || forms.displayPlanningAndConstructionLaw) {
				stepAction = 'prev';
			} else {
				stepAction = 'prev-skip-forth-step';
			}
		}
		setValidityChanged(0);
		updateStep(stepAction);
	};

	return (
		<div className="nav-arrows flex center">
			<button
				type="button"
				className={`nav-arrows__arrow nav-arrows__arrow_prev flex center ${currentStep > 0 ? 'shown' : 'invisible'} `}
				onClick={() => handleStepClick('prev')}
				disabled={currentStep === 0}
			>
				<span className="button-text">הקודם</span> <MdOutlineNavigateNext />
			</button>
			{currentStep !== 5 ? (
				<button
					type="button"
					disabled={!chosenProfessions?.length}
					className={`nav-arrows__arrow nav-arrows__arrow_next flex center ${!chosenProfessions?.length ? 'nav-arrows__next-arrow_disabled' : ''}`}
					onClick={() => handleStepClick('next')}
				>
					<MdOutlineNavigateBefore />
					<span className="button-text">הבא</span>
				</button>
			) : (
				<button className={`nav-arrows-send nav-arrows-send_active `} onClick={sendForm} type="button">
					{isLoading ? 'שולח...' : 'שלח'}
				</button>
			)}
		</div>
	);
}
