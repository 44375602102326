import { FC, createElement } from 'react';
import { MunInfoConfig } from './PreviewMunInfo.config';
import { MunIconList } from './PreviewMunInfo.const';
import { FiMapPin } from 'react-icons/fi';
import './style.scss';
type MunInfoProps = {
	mun: Record<string, any>;
};

const PreviewMunInfo: FC<MunInfoProps> = ({ mun }) => {
	const { fillObjectWithValues } = MunInfoConfig();
	const adress1 = mun?.adress1?.trim();
	const streetNumber = mun?.street_number?.trim();
	const adress2 = mun?.adress2?.trim();
	const fullAddress = [adress1, streetNumber, adress2].filter(Boolean).join(' ');
	const receptionInfo = [mun?.remarks_1, mun?.remarks_2, mun?.remarks_3, mun?.remarks_4];
	const munItems = mun ? fillObjectWithValues(MunIconList, mun) : [];

	return (
		<div className="mun-info-rows-wrapper flex column status-page-mun-info">
			<div className="mun-info-row">
				<FiMapPin />
				<span className="value">{fullAddress}</span>
			</div>
			{munItems.length > 0 && (
				<div className="mun-info-rows-wrapper flex column">
					{munItems.map((item: any) =>
						item.text !== null ? (
							<div key={item.name} className="mun-info-row">
								{createElement(item.icon)}
								<span className="value">{item.text}</span>
							</div>
						) : null
					)}
				</div>
			)}
			<div className="reception-info-container flex column">
				<span className="reception-info-title">קבלת קהל:</span>
				<span className="reception-info flex column">{receptionInfo.map((info, index) => (info?.length ? <span key={index}>{info}</span> : null))}</span>
			</div>
		</div>
	);
};

export default PreviewMunInfo;
