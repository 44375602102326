import { useEffect } from 'react';
import mainService from '../../api/MainService';
import { useStore } from '../../store';
import './component.style.scss';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import { createDossierResult } from '../../ts/interfaces';
import { getFormDataInLocalStorage } from '../../utils/localStorage';

export default function NavArrows() {
	const { professionPhraseFormValues } = useStore().professionPhraseFormContainerContext;
	const { owners } = useStore().ownersFormContainerContext;
	const { setForms, dossierCreated, dossierCreatedSuccessfully, dossierDidNotCreatedSuccessfully, setLoading, setNumero, setPdf, isLoading } = useStore().formsContainerContext;
	const { updateStep, goToToolTipPage, currentStep } = useStore().activeStepContext;
	const { isRequestCabahutPrivileged, thereIsOneProfessionWithoutPrivilege, mifratLogic, checkTazhirNegishut, chosenProfessions } = useStore().chosenProfessionsContext;
	const { setValidityChanged, validityChanged } = useStore().formsContainerContext;
	const { thereIsNegishut } = checkTazhirNegishut();

	async function sendForm() {
		const form = document.querySelector('form');
		if (form) {
			const formElements = form.elements;
			let firstInvalidElement: any;

			Array.from(formElements).forEach((element) => {
				if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
					if (!element.checkValidity()) {
						if (!firstInvalidElement) {
							firstInvalidElement = element;
						}
					}
				}
			});

			if (firstInvalidElement) {
				firstInvalidElement.focus();
				const labelElement: any = document.querySelector(`label[aria-label="העלאת ${firstInvalidElement.id}"]`);
				if (labelElement) {
					labelElement.focus();
				}
				setValidityChanged(validityChanged + 1);
				return;
			}
		}

		try {
			setLoading(true);
			let is_getting_cabahut_priv = true;

			// Fetch and parse data from localStorage, with error handling for corrupted JSON
			let forms: any = {};
			let formTemplateAnswers = {};
			try {
				const formFromLocalStorage = localStorage.getItem('forms');
				forms = formFromLocalStorage ? JSON.parse(formFromLocalStorage) : {};
				const formTemplateAnswersFromLocalStorage = localStorage.getItem('formTemplateAnswers');
				formTemplateAnswers = formTemplateAnswersFromLocalStorage ? JSON.parse(formTemplateAnswersFromLocalStorage) : {};
			} catch (parseError) {
				console.error('Failed to parse data from localStorage:', parseError);
			}

			// Check chosenPhrases safely
			const chosenPhrases = forms.formSummary?.chosenPhrases
				? Object.keys(forms.formSummary.chosenPhrases).filter((key) => {
						const phrase = forms.formSummary.chosenPhrases[key];
						return phrase?.licensing_factor_code === 6;
					})
				: [];

			// Check if all chosen phrases meet a specific condition
			const allPhrasesAreTrue = chosenPhrases.every((key) => {
				const phrase = forms.formSummary?.chosenPhrases?.[key];
				return phrase?.is_true === 1;
			});

			// Check other conditions in form summary safely
			const placed_is_ok = forms.formSummary?.where_is_placed === '4';
			const multy_building_flag = forms.formSummary?.multy_building_flag === '1';
			const is_entrance = forms.formSummary?.idsend === 1;

			// Update is_getting_cabahut_priv based on conditions
			if (multy_building_flag && (!is_entrance || forms.chosenProfessions?.some((prof: any) => prof.profession === '6.4' || forms.businessDetails?.surface > 300))) {
				is_getting_cabahut_priv = false;
			}

			if (!allPhrasesAreTrue || !placed_is_ok) is_getting_cabahut_priv = false;

			// Transform chosen phrases if they exist
			const transformedChosenPhrases = transformChosenPhrases(forms.formSummary?.chosenPhrases || {});

			// Update forms state with transformed data and new flags
			setForms((prev: any) => ({
				...prev,
				platfromFlag: 'openRequest',
				formSummary: {
					chosenPhrases: transformedChosenPhrases,
					cabahut_maslul: String(professionPhraseFormValues?.cabahut_maslul && is_getting_cabahut_priv ? '1' : '0'),
					cabahut_ptor: String(professionPhraseFormValues?.cabahut_ptor && is_getting_cabahut_priv ? '1' : '0'),
				},
				businessDetails: {
					...forms.businessDetails,
					idstreet: parseInt(forms.formSummary?.where_is_placed || '0', 10),
					ceilling_height: forms.businessDetails?.ceilling_height ? Number(forms.businessDetails.ceilling_height) : null,
					nagish: thereIsNegishut ? 1 : 0,
				},
				formTemplateAnswers,
			}));

			// Prepare data for submission
			forms.businessDetails = {
				...forms.businessDetails,
				idstreet: parseInt(forms.formSummary?.where_is_placed || '0', 10),
			};
			forms.formTemplateAnswers = formTemplateAnswers;
			forms.formSummary.chosenPhrases = transformedChosenPhrases;

			const modifiedForms = modifyForms(forms);

			// Send data to the service
			const res = await mainService.createDossier(modifiedForms);

			if (res.dossierId) {
				setNumero(res.numero);
				setPdf(res.formSummaryPdf);
				dossierCreatedSuccessfully();
				localStorage.clear();
			} else {
				dossierDidNotCreatedSuccessfully();
			}
		} catch (error) {
			console.error('Error in sending form:', error);
			dossierDidNotCreatedSuccessfully();
		} finally {
			setLoading(false);
		}
	}

	function removeEmptyKeys(obj: any) {
		const newObj = { ...obj };
		for (const key in newObj) {
			if (newObj[key] === '' || newObj[key] === null) {
				delete newObj[key];
			} else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
				newObj[key] = removeEmptyKeys(newObj[key]);
			}
		}
		return newObj;
	}

	const transformChosenPhrases = (chosenPhrasesObj: any) => {
		return Object.keys(chosenPhrasesObj).map((key) => ({
			id: key,
			...chosenPhrasesObj[key],
		}));
	};

	function modifyForms(forms: any) {
		const modifiedForms = { ...forms };
		for (const key in modifiedForms) {
			if (key === 'businessDetails') {
				modifiedForms[key] = substringAddresses(modifiedForms[key]);
				modifiedForms[key] = removeEmptyKeys(modifiedForms[key]);
			} else if (key === 'owners') {
				modifiedForms[key] = modifiedForms[key].map((owner: any) => {
					const modifiedOwner = substringAddresses(owner);
					return removeEmptyKeys(modifiedOwner);
				});
			}
		}
		return modifiedForms;
	}

	function substringAddresses(data: any) {
		if (data.address1) {
			data.address1 = data.address1.substring(0, 24);
		}
		if (data.adress1) {
			data.adress1 = data.adress1.substring(0, 24);
		}
		return data;
	}

	useEffect(() => {
		goToToolTipPage(dossierCreated);
	}, [dossierCreated]);

	const handleStepClick = (direction: string) => {
		if (direction === 'next' || direction === 'skip-forth-step') {
			const formSummaryFromLocalStorage = localStorage.getItem('forms');
			const forms = formSummaryFromLocalStorage ? JSON.parse(formSummaryFromLocalStorage) : {};
			const formElements = document.querySelectorAll('form');

			let isAllFormsValid = true;
			let firstInvalidElement: any;

			formElements.forEach((form) => {
				const formElements = form.elements;

				Array.from(formElements).forEach((element) => {
					if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element instanceof HTMLSelectElement) {
						if (!element.checkValidity()) {
							isAllFormsValid = false;
							if (!firstInvalidElement) {
								firstInvalidElement = element;
							}
							if (currentStep === 1 && element.type === 'file') {
								const ownerIndex = form.getAttribute('name');
								const fileName = element.id;
								const parentElement: any = element.closest('.file-wrapper');

								if (parentElement) {
									parentElement.style.position = 'relative';
									parentElement.focus();
								}

								if (ownerIndex !== null) {
									const numericOwnerIndex = Number(ownerIndex);
									if (!isNaN(numericOwnerIndex) && numericOwnerIndex >= 0 && numericOwnerIndex < owners.length) {
										const owner = owners[numericOwnerIndex];
										if (owner && owner.files && owner.files[fileName] && owner.files[fileName].file) {
											isAllFormsValid = true;
											firstInvalidElement = null;
										}
									}
								}
							}
						}
					}
				});
			});

			if (currentStep === 3 && forms.businessDetails.agra2_flag === '1') {
				const extraDetailsBoolFromLocalStorage = getFormDataInLocalStorage('extraDetailsFormValid');
				if (!extraDetailsBoolFromLocalStorage) return;
			}

			if (firstInvalidElement) {
				if (currentStep === 4 || currentStep === 1) {
					const labelElement = document.getElementById(`${firstInvalidElement.name}_title`);
					if (labelElement) {
						labelElement.focus();
						if (document.activeElement === labelElement) {
							setValidityChanged(validityChanged + 1);
							return;
						}
					}
				}

				firstInvalidElement.focus();
				setValidityChanged(validityChanged + 1);
				return;
			}

			if (!isAllFormsValid) return;
		}

		let stepAction = direction;
		if (direction === 'next' && currentStep === 3) {
			if (!chosenProfessions || !chosenProfessions?.length) return;
			if (thereIsOneProfessionWithoutPrivilege) {
				stepAction = mifratLogic ? 'next' : 'skip-forth-step';
			}
		} else if (direction === 'prev' && currentStep === 5) {
			if (!thereIsOneProfessionWithoutPrivilege || mifratLogic) {
				stepAction = 'prev';
			} else {
				stepAction = 'prev-skip-forth-step';
			}
		}
		setValidityChanged(0);
		updateStep(stepAction);
	};

	return (
		<div className="nav-arrows flex center">
			<button
				type="button"
				className={`nav-arrows__arrow nav-arrows__arrow_prev flex center ${currentStep > 0 ? 'shown' : 'invisible'} `}
				onClick={() => handleStepClick('prev')}
				disabled={currentStep === 0}
			>
				<span className="button-text">הקודם</span> <MdOutlineNavigateNext />
			</button>
			{currentStep !== 5 ? (
				<button type="button" className={`nav-arrows__arrow nav-arrows__arrow_next flex center`} onClick={() => handleStepClick('next')}>
					<MdOutlineNavigateBefore />
					<span className="button-text">הבא</span>
				</button>
			) : (
				<button className={`nav-arrows-send nav-arrows-send_active `} onClick={sendForm} type="button">
					{isLoading ? 'שולח...' : 'שלח'}
				</button>
			)}
		</div>
	);
}
