import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export const useRequiredFilesForm = () => {
	const canvasRef = useRef<SignatureCanvas | null>(null);
	const [requiredFilesFormValues, setRequiredFilesFormValues] = useState({
		files: [],
		signature: '',
	});

	const [globalRequiredFiles, setGlobalRequiredFiles] = useState({});
	const [requiredFilesFormIsValid, setRequiredFilesFormIsValid] = useState(false);
	const [errorMessages, setErrorMessages] = useState<React.ReactNode[]>([]);

	const _readFile = (file: File, fileEntity: string) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			const { result } = reader;
			setRequiredFilesFormValues((current: any) => {
				const updatedFiles = current.files.map((item: { descrip: string }) =>
					item.descrip === fileEntity
						? {
								...item,
								file: result as string,
								name: fileEntity,
								originName: file.name,
						  }
						: item
				);

				return { ...current, files: updatedFiles };
			});
		};
	};

	function checkRequiredFilesFormValid() {
		let isValid = true;
		const requiredFilesWithErrors: any[] = [];

		if (!Array.isArray(requiredFilesFormValues.files)) {
			isValid = false;
		}

		requiredFilesFormValues.files.forEach((file: any) => {
			if (file.isRequired && (!file.file || file.file === '')) {
				isValid = false;
				requiredFilesWithErrors.push(file.descrip);
			}
		});

		setRequiredFilesFormIsValid(isValid);

		if (!isValid) {
			const errorMessages = requiredFilesWithErrors.map((fileDescrip) => (
				<span key={fileDescrip} className="required">
					חובה להעלות את הקובץ: {fileDescrip}
				</span>
			));
			setErrorMessages(errorMessages);
		} else {
			setErrorMessages([]);
		}
	}

	const removeFile = (descrip: string) => {
		setRequiredFilesFormValues({
			...requiredFilesFormValues,
			files: requiredFilesFormValues.files.map((file: any) => {
				if (file.descrip === descrip) {
					const { isUploaded, ...updatedFile } = file;
					return {
						...updatedFile,
						file: '',
						originName: '',
					};
				}
				return file;
			}) as any,
		});
	};

	const handleChange = (event: { target: any }) => {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'file' ? target.files[0] : target.value;

		if (target.type === 'file') {
			const fileObj = requiredFilesFormValues.files.find((item: any) => item.descrip?.trim() === name);

			if (fileObj) {
				_readFile(value, name);
				setRequiredFilesFormValues({
					...requiredFilesFormValues,
					files: requiredFilesFormValues.files.map((item: any) => (item.descrip === name ? { ...item, isUploaded: true } : item)) as any,
					[name]: value,
				});
			}
		} else {
			setRequiredFilesFormValues({
				...requiredFilesFormValues,
				[name]: value,
			});
		}
	};

	const setSignatureValue = (value: any) => {
		setRequiredFilesFormValues((current) => {
			return { ...current, signature: value };
		});
	};

	return {
		setRequiredFilesFormValues,
		canvasRef,
		removeFile,
		checkRequiredFilesFormValid,
		requiredFilesFormValues,
		requiredFilesFormIsValid,
		handleChange,
		setSignatureValue,
		errorMessages,
		setGlobalRequiredFiles,
		globalRequiredFiles,
	};
};
