import { Api } from './Api';

const params = new URLSearchParams(window.location.search);
const munParamValue = params.get('mun');

class MainService extends Api {
	constructor(
		private baseUrl: string,
		private headers: any
	) {
		super();
	}
	getStaticData(staticDataTag: string) {
		return this.axiosRequest(`${this.baseUrl}/static-data/${staticDataTag}`, {
			headers: this.headers,
		});
	}

	checkSha(sha: string) {
		return this.axiosRequest(`${this.baseUrl}/auth/validate-sha`, {
			method: 'POST',
			headers: this.headers,
			data: { sha },
		});
	}

	getProfessionsByGroupId(id: any) {
		return this.axiosRequest(`${this.baseUrl}/professions?group_id=${id}`, {
			headers: this.headers,
		});
	}

	createDossier(data: any) {
		return this.axiosRequest(`${this.baseUrl}/dossier`, {
			method: 'POST',
			headers: this.headers,
			data: data,
		});
	}

	getPdfSummaryBlob(path: string) {
		return this.axiosRequest(`${this.baseUrl}/storage/download`, {
			method: 'POST',
			headers: this.headers,
			responseType: 'blob',
			data: { path },
		});
	}

	async getImage(path: string) {
		const buffer = await this.axiosRequest(`${this.baseUrl}/storage/read_file`, {
			method: 'POST',
			headers: this.headers,
			data: { file_path: path },
		});
		return buffer.data;
	}

	getMun() {
		return this.axiosRequest(`${this.baseUrl}/mun/one`, {
			headers: this.headers,
		});
	}

	getSocietyData() {
		return this.axiosRequest(`${this.baseUrl}/societies`, {
			headers: this.headers,
		});
	}

	sendTempPhone(phone: string) {
		return this.axiosRequest(`${this.baseUrl}/auth/temp/phone`, {
			method: 'POST',
			headers: this.headers,
			data: { phone },
		});
	}

	validateTempCode(phone: string, code: string) {
		return this.axiosRequest(`${this.baseUrl}/auth/temp/validate_code`, {
			method: 'POST',
			headers: this.headers,
			data: { phone, code },
		});
	}

	getProfessionPhrases(professions: string[]) {
		return this.axiosRequest(`${this.baseUrl}/static-data/profession-phrase`, {
			method: 'POST',
			headers: this.headers,
			data: professions,
		});
	}

	getCheckListIdsByIdProfession(idProfession: number) {
		return this.axiosRequest(`${this.baseUrl}/dossier-check-list/by-idprofession/${idProfession}`, {
			headers: this.headers,
		});
	}

	getFormTemplatesByProfessionIds(idProfessionsArray: number[]) {
		return this.axiosRequest(`${this.baseUrl}/form-builder/byProfession`, {
			method: 'POST',
			headers: this.headers,
			data: idProfessionsArray,
		});
	}
}

const envUrl = {
	production: 'https://rishuy-api-new.citysys.co.il/api',
	development: 'https://qa-rishuy-api.citysys.co.il/api',
	local: 'http://localhost:3002/api',
};

const mainService = new MainService(envUrl[process.env.REACT_APP_NODE_ENV as keyof typeof envUrl], {
	'Content-Type': 'application/json',
	'x-mun-nickname': munParamValue ?? 'test_mun',
});

export default mainService;
