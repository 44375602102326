import './component.style.scss';
import { useStore } from '../../store';
import sorryRobot from '../../images/warning-robot 1.svg';

export default function FinalToolTip() {
	const { dossierCreated, formSummaryPdf, numero } = useStore().formsContainerContext;
	const { owners } = useStore().ownersFormContainerContext;

	const openPdfSummary = async () => {
		const binaryString = window.atob(formSummaryPdf);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; ++i) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		const pdfBlob = new Blob([bytes], { type: 'application/pdf' });
		const pdfObjectURL = URL.createObjectURL(pdfBlob);
		window.open(pdfObjectURL, '_blank');
	};

	return (
		<>
			{dossierCreated === true ? (
				<section className="tooltip">
					<h2 className="tooltip__title"> בקשתך לרישיון עסק נשלחה בהצלחה והועברה להמשך טיפולנו </h2>
					<p style={{ fontSize: 25 }}>{`מספר בקשה: ${numero}`}</p>
					{owners[0].email && <p style={{ fontSize: 25 }}>{`טופס הבקשה נשלח למייל: ${owners[0].email}`}</p>}
					<div style={{ display: 'flex', gap: 5, alignItems: 'center' }} onClick={() => openPdfSummary()}>
						{formSummaryPdf.length > 1 && <div className="eye"></div>}
						<button type="button" className={`tooltip__pdf ${formSummaryPdf.length > 1 && 'tooltip__pdf_active'}`} disabled={formSummaryPdf.length < 1}>
							{formSummaryPdf.length > 1 ? 'לצפייה בטופס הבקשה' : 'טופס בהורדה...'}
						</button>
					</div>
				</section>
			) : (
				<section className="tooltip">
					<img src={sorryRobot} alt="" />
					<h2>משהו השתבש, בקשתך לא נשלחה</h2>
					<p style={{ fontSize: 16 }}>אל דאגה, שמרנו את הפרטים</p>
					<button type="button" className="try-again" onClick={() => window.location.reload()}>
						נסה שוב
					</button>
				</section>
			)}
		</>
	);
}
