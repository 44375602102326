export const businessDetailsMock: any = {
	type: '0',
	osek: undefined,
	takana_39_email_flag: '0',
	takana_39_sms_flag: '0',
	telephon1: undefined,
	address3: '',
	surface: undefined,
	agra3: undefined,
	workers: undefined,
	telephon2: undefined,
	address2: '',
	area: '',
	zipcode: undefined,
	post: undefined,
	seats: undefined,
	mngname: undefined,
	email: undefined,
	address1: '',
	agra2_flag: '0',
	buildingNumber: undefined,
	profession_descripl: undefined,
	gush: undefined,
	helka: undefined,
	neches: undefined,
	rooms: undefined,
	number_of_floors: undefined,
	multy_building_flag: undefined,
	floor_number: undefined,
	number_of_shotgun: undefined,
	hazard_materials_flag: undefined,
	spa_flag: undefined,
	open_space_flag: undefined,
	number_of_beds: undefined,
	ceilling_height: undefined,
	weapon_flag: undefined,
	animal_show_flag: undefined,
	storage_height: undefined,
	date_send_first: null,
	date_nitra_first: null,
	nagish: undefined,
	walking_order: undefined,
};
