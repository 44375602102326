// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessibility-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  direction: rtl; /* Set text direction to RTL */
}

.accessibility-container h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.accessibility-container p {
  margin-bottom: 15px;
}

.accessibility-container ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: right; /* Ensure the list aligns to the right */
  display: inline-block;
}

.accessibility-container ul li {
  margin-bottom: 10px;
}

.accessibility-container .contact-info {
  margin-top: 20px;
}

.accessibility-container .contact-info p {
  margin: 5px 0;
}

.back-button {
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.back-button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/accessibilty.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,8BAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA,EAAA,8BAAA;AACD;;AAEA;EACC,gBAAA;EACA,mBAAA;AACD;;AAEA;EACC,mBAAA;AACD;;AAEA;EACC,qBAAA;EACA,kBAAA;EACA,iBAAA,EAAA,wCAAA;EACA,qBAAA;AACD;;AAEA;EACC,mBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,aAAA;AACD;;AAEA;EACC,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AACD;;AAEA;EACC,yBAAA;AACD","sourcesContent":[".accessibility-container {\n\tpadding: 20px;\n\tbackground-color: #f9f9f9;\n\tborder-radius: 8px;\n\tfont-family: Arial, sans-serif;\n\tline-height: 1.6;\n\tcolor: #333;\n\tmax-width: 800px;\n\tmargin: 0 auto;\n\tdirection: rtl; /* Set text direction to RTL */\n}\n\n.accessibility-container h2 {\n\tfont-size: 1.5em;\n\tmargin-bottom: 15px;\n}\n\n.accessibility-container p {\n\tmargin-bottom: 15px;\n}\n\n.accessibility-container ul {\n\tlist-style-type: disc;\n\tpadding-left: 20px;\n\ttext-align: right; /* Ensure the list aligns to the right */\n\tdisplay: inline-block;\n}\n\n.accessibility-container ul li {\n\tmargin-bottom: 10px;\n}\n\n.accessibility-container .contact-info {\n\tmargin-top: 20px;\n}\n\n.accessibility-container .contact-info p {\n\tmargin: 5px 0;\n}\n\n.back-button {\n\tpadding: 10px 20px;\n\tmargin-bottom: 20px;\n\tbackground-color: #007bff;\n\tcolor: white;\n\tborder: none;\n\tborder-radius: 4px;\n\tcursor: pointer;\n\tfont-size: 16px;\n\ttext-align: center;\n}\n\n.back-button:hover {\n\tbackground-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
